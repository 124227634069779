import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'features/localization/localizationSlice';
//components
import ContainerPageWrapper from 'components/container-page-wrapper/ContainerPageWrapper';
import HeaderPageWrapper from 'components/header-page-wrapper/HeaderPageWrapper';
import FilterWrapper from 'components/form/filter-wrapper/FilterWrapper';
import { TabNavLink } from 'components/nav/NavLinks';
import { exportFile } from 'components/excelFile';
import { DevicesTable } from './DevicesTable';
import { Button } from 'antd';
import { cache } from './CellRenderers';
import {
  Can,
  entities,
  FeatureFlag,
  GlobalRoles,
  useCan,
  useCanFeatureFlag
} from 'features/permissions';
//slices
import { useCompanies, useSubCompanies, useCurrentCompany } from 'features/company/companySlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import {
  useDevices,
  useFleets,
  useIsFetching as isFleetsFetching
} from 'features/fleets/fleetsSlice';
import { useDeletedDevices } from 'features/devices/devicesDeletedSlice';
import useDebounce from 'utils/hooks/useDebounce';
import { useDevicesStats } from 'features/devices/devicesStatsSlice';
import { useVehicles } from 'features/fleets/fleetsSlice';
import {
  deleteDeviceApi,
  restoreDeviceApi,
  saveDeviceFleetsApi
} from 'features/devices/devicesSlice';
//constants
import { XLSX_FILE_TITLE, columnWidth } from './constants';
//helpers and methods
import { toLower, trim } from 'lodash';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { prepareDataForMultiselect } from 'utils/filters';
import { prepareFileForExcelExport } from './helpers';
import { isIQCamera } from 'features/camera/CameraModelConfig';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import { sortStrings } from 'utils/strings';
import { BUTTON_IDS } from 'utils/globalConstants';
import { useDevicesBulkEdit } from './BulkEdit/useDevicesBulkEdit';
import { DevicesBulkEditTable } from './BulkEdit/DevicesBulkEditTable';
import { useDeviceModelsList } from 'features/device_models/deviceModelsSlice';
import { useIQCameraDevicesConfig } from 'features/company_config';
import { useGpioConfigurations } from 'services/nextgen/ngGpioConfigurationApi';
import services from 'features/permissions/services';

const Devices = ({ fleetId, gpioConfigurationId }) => {
  const allDevices = useDevices('vehicles,devices,services');
  const deletedDevices = useDeletedDevices();
  const currentCompany = useCurrentCompany();
  const dispatch = useDispatch();
  const [devices, setDevices] = useState(allDevices);
  const fleets = useFleets();
  const isFetchingFleets = isFleetsFetching();
  const companies = useCompanies();
  const subCompanies = useSubCompanies();
  const types = useDeviceTypesList();
  const devicesStats = useDevicesStats();
  const [filterText, setFilterText] = useState('');
  const [filterFleets, setFilterFleets] = useState([]);
  const [filterGpioConfig, setFilterGpioConfig] = useState([]);
  const debouncedSearchText = useDebounce(trim(filterText), 300);
  const [tableRef, setTableRef] = useState(null);
  const [filterTypes, setFilterTypes] = useState([]);
  const [filterCompanies, setFilterCompanies] = useState([]);
  const { data: gpioConfigurations } = useGpioConfigurations();
  const history = useHistory();
  const vehicles = useVehicles();
  const localization = useLocalization();
  const { t } = useTranslation();
  const [filterModelOptions, setFilterModelOptions] = useState([]);
  const can = useCan();
  const canControlGpioConfiguration = can({
    oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin],
    oneOfCompanyServices: [services.GPIO]
  });
  const getDeviceModelName = useCallback(
    modelName =>
      modelName === 'MultiIQ' &&
      !can({ oneOfFeatureFlags: [FeatureFlag.evoPhase1.flag, FeatureFlag.evoPhaseTGE.flag] })
        ? 'IQ Camera'
        : modelName || '',
    [can]
  );

  const {
    iqCameraDevicesConfig,
    isFetching: isFetchingIQCameraDevicesConfig
  } = useIQCameraDevicesConfig({
    companyId: currentCompany?.id,
    deviceIds: (allDevices?.filter(d => !!d.id && isIQCamera(d)) || []).map(i => i.id)
  });

  const location = useLocation();
  const filterTab = useMemo(() => {
    switch (location.pathname) {
      case '/settings/devices/assigned':
        return 'assigned';
      case '/settings/devices/standalone':
        return 'standalone';
      case '/settings/devices/deleted':
        return 'deleted';
      default:
        return 'all';
    }
  }, [types, location]);

  const devicesModels = useDeviceModelsList();
  const { getModelOptionByTypes, allModelOption } = useMemo(() => {
    const getModelOptions = models =>
      prepareDataForMultiselect(
        models?.slice()?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Common.AllModels'),
        null
      );
    const getModelOptionByTypes = typeOptions => {
      const models = typeOptions.reduce((a, typeOption) => {
        if (typeOption.checked) {
          const modelsOfType =
            devicesModels?.filter(
              model => String(model.deviceType?.id) === String(typeOption.id)
            ) || [];
          return [...a, ...modelsOfType];
        } else {
          return a;
        }
      }, []);
      return getModelOptions(models);
    };
    return {
      allModelOption: getModelOptions(devicesModels),
      getModelOptionByTypes
    };
  }, [t, devicesModels]);

  const modelOptions = useMemo(() => {
    if (!filterTypes || !filterTypes.length) {
      return allModelOption;
    }

    const allTypesChecked = filterTypes?.length && filterTypes.every(type => type.checked);

    const baseModelOptions = allTypesChecked
      ? allModelOption
      : filterTypes?.length
      ? getModelOptionByTypes(filterTypes)
      : allModelOption;

    const updatedModelOptions = baseModelOptions.map(opt => {
      const matchedFilterOption = filterModelOptions.find(
        filtered => String(filtered.id) === String(opt.id)
      );

      return {
        ...opt,
        checked: matchedFilterOption ? matchedFilterOption.checked : allTypesChecked || opt.checked
      };
    });

    return updatedModelOptions;
  }, [filterTypes, filterModelOptions, getModelOptionByTypes]);

  useEffect(() => {
    dispatch(setBackButton(false));
    dispatch(setPageTitle(t('Devices.Devices')));
  }, [dispatch, t]);

  useEffect(() => {
    setFilterCompanies(prepareDataForMultiselect(subCompanies, t('Common.AllCompanies'), null));
  }, [subCompanies, t]);

  useEffect(() => {
    setFilterTypes(
      prepareDataForMultiselect(
        types.slice().sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)),
        t('Common.AllTypes'),
        null
      )
    );
  }, [types, subCompanies, t]);

  useEffect(() => {
    const gpioConfigurationOptions = [...gpioConfigurations];
    gpioConfigurationOptions.push({ id: -1, name: t('Common.NoGpioConfiguration') });

    setFilterGpioConfig(
      prepareDataForMultiselect(
        gpioConfigurationOptions,
        t('Common.AllGpioConfigs'),
        gpioConfigurationId ? [gpioConfigurationId] : null
      )
    );
  }, [gpioConfigurations, t]);

  useEffect(() => {
    const subCompaniesIdsFromFilter = filterCompanies
      .filter(comp => comp.checked)
      .map(comp => comp.id);

    const fleetsOptions = fleets.filter(
      fleet => fleet.id && fleet.company && subCompaniesIdsFromFilter.includes(fleet.company.id)
    );
    fleetsOptions.push({ id: -1, name: t('Common.NoFleet') });
    setFilterFleets(
      prepareDataForMultiselect(fleetsOptions, t('Common.AllFleets'), fleetId ? [fleetId] : null)
    );
  }, [fleets, filterCompanies, t]);

  useEffect(() => {
    let devicesToFilter = allDevices;
    if (filterTab === 'deleted') {
      devicesToFilter = deletedDevices;
    }
    setDevices(
      devicesToFilter
        .filter(device => {
          let validDevice = true;

          //Filter by device category
          if (filterTab === 'all') {
            validDevice = true;
          }
          if (filterTab === 'assigned') {
            validDevice = device.vehicleInfo.id !== undefined;
          }
          if (filterTab === 'standalone') {
            validDevice = device.vehicleInfo.id === undefined;
          }
          // Filter by search field

          if (debouncedSearchText) {
            validDevice =
              validDevice &&
              [
                device.name,
                device?.imei,
                device?.vehicle?.name,
                device?.vehicle?.registration,
                device?.serialNumber,
                device?.externalId,
                device?.sim,
                device?.phone,
                device?.note
              ].some(value => toLower(value).indexOf(toLower(debouncedSearchText)) > -1);
          }
          // Filter by companies
          const checkedCompaniesIds = filterCompanies
            .filter(company => company.checked)
            .map(company => parseInt(company.id, 10));
          if (!(checkedCompaniesIds.indexOf(0) > -1)) {
            validDevice =
              validDevice && checkedCompaniesIds.indexOf(parseInt(device.companyId, 10)) > -1;
          }

          //Filter by fleets
          let checkedFleetsIds = filterFleets
            .filter(fleet => fleet.checked)
            .map(fleet => parseInt(fleet.id, 10));
          if (!(checkedFleetsIds.indexOf(0) > -1)) {
            let isDeviceInFleet = false;
            (device.fleetInfo || []).forEach(fleet => {
              isDeviceInFleet =
                isDeviceInFleet || checkedFleetsIds.indexOf(parseInt(fleet.id, 10)) > -1;
            });
            if (!isDeviceInFleet && checkedFleetsIds.indexOf(-1) > -1) {
              isDeviceInFleet =
                !Array.isArray(device.fleetInfo) || !device.fleetInfo.find(fleet => true)?.id;
            }
            validDevice = validDevice && isDeviceInFleet;
          }

          //Filter by type
          const checkedTypesIds = filterTypes
            .filter(type => type.checked)
            .map(type => parseInt(type.id, 10));
          if (!(checkedTypesIds.indexOf(0) > -1)) {
            validDevice = validDevice && checkedTypesIds.indexOf(parseInt(device.type.id, 10)) > -1;
          }

          //Filter by Model
          const checkedModelIds = modelOptions
            .filter(type => type.checked)
            .map(type => parseInt(type.id, 10));
          if (!(checkedModelIds.indexOf(0) > -1)) {
            validDevice =
              validDevice && checkedModelIds.indexOf(parseInt(device.model.id, 10)) > -1;
          }

          if (filterTab !== 'deleted') {
            // Filter by gpio configuration
            const checkeGpioConfigurationIds = filterGpioConfig
              .filter(type => type.checked)
              .map(type => parseInt(type.id, 10));
            if (!(checkeGpioConfigurationIds.indexOf(0) > -1)) {
              validDevice =
                validDevice &&
                device.services.includes(services.GPIO) &&
                (checkeGpioConfigurationIds.indexOf(parseInt(device?.gpioTemplate?.id, 10)) > -1 ||
                  (checkeGpioConfigurationIds.indexOf(-1) > -1 &&
                    device?.gpioTemplate?.id === undefined));
            }
          }

          return validDevice;
        })
        .sort((a, b) => sortStrings(a?.name, b?.name))
    );
  }, [
    allDevices,
    deletedDevices,
    history.location.state,
    filterFleets,
    filterText,
    filterCompanies,
    filterGpioConfig,
    debouncedSearchText,
    companies,
    filterTab,
    filterTypes,
    modelOptions
  ]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [devices, tableRef]);

  useEffect(() => {
    if (tableRef) {
      cache.clearAll();
      tableRef.recomputeRowHeights();
    }
  }, [devices, tableRef]);

  const handleExportExcel = () => {
    const data = prepareFileForExcelExport({
      devices,
      vehicles,
      companies,
      countryCode: currentCompany.countryCode,
      devicesStats,
      localization,
      dateFormat: localization.formats.time.formats.dby_imsp,
      iqCameraDevicesConfig,
      canControlGpioConfiguration,
      getDeviceModelName
    });
    dispatch(
      exportFile(data, {
        width: columnWidth,
        title: XLSX_FILE_TITLE,
        dateFormat: localization.formats.time.formats.dby_imsp
      })
    );
  };

  const handleAction = actionObject => {
    if (actionObject.action === 'restore') {
      dispatch(restoreDeviceApi(actionObject.data));
    }
  };

  const handleDeleteAction = data => () => {
    dispatch(saveDeviceFleetsApi(data.id, []));
    dispatch(deleteDeviceApi(data));
  };

  const {
    BtnsWithBulkEdit,
    isBulkEditing,
    tableProps,
    isBulkAssigningAgreement
  } = useDevicesBulkEdit({
    devices,
    isLoading: isFetchingFleets,
    handleDeleteAction,
    filterTab
  });

  const companiesSelectTitle = useMemo(
    () =>
      filterCompanies?.some(value => !value.checked)
        ? t('Common.Companies')
        : t('Common.AllCompanies'),
    [filterCompanies]
  );

  const fleetSelectTitle = useMemo(
    () =>
      filterFleets?.some(value => !value.checked) ? t('Common.Fleets') : t('Common.AllFleets'),
    [filterFleets]
  );

  const typeSelectTitle = useMemo(
    () => (filterTypes?.some(value => !value.checked) ? t('Common.Types') : t('Common.AllTypes')),
    [filterTypes]
  );

  const modelSelectTitle = useMemo(
    () =>
      modelOptions?.some(value => !value.checked) ? t('Common.Models') : t('Common.AllModels'),
    [modelOptions]
  );

  const handleTypeFilter = useCallback(
    v => {
      setFilterTypes(v);
      setFilterModelOptions(getModelOptionByTypes(v));
    },
    [setFilterTypes, setFilterModelOptions, getModelOptionByTypes]
  );

  return (
    <>
      <ContainerPageWrapper>
        <HeaderPageWrapper>
          <div>
            <TabNavLink
              to="/settings/devices/all"
              isActive={(match, location) => {
                return ['/', '/settings', '/settings/devices', '/settings/devices/all'].includes(
                  location.pathname
                );
              }}
            >
              {t('Devices.Lens.All')}
            </TabNavLink>
            <TabNavLink exact to={'/settings/devices/assigned'}>
              {t('Devices.Lens.Assigned')}
            </TabNavLink>
            <TabNavLink exact to={'/settings/devices/standalone'}>
              {t('Devices.Lens.Standalone')}
            </TabNavLink>
            {!isBulkEditing && (
              <TabNavLink exact to={'/settings/devices/deleted'}>
                {t('Devices.Lens.Deleted')}
              </TabNavLink>
            )}
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <BtnsWithBulkEdit>
              <Can everyEntity={[entities.DEVICE_CREATE]}>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => {
                    history.push(`/settings/devices/newDevice`);
                  }}
                  id={BUTTON_IDS.devicesAddNew}
                >
                  {t('Devices.ActualForm.AddNewDevice')}
                </Button>
              </Can>
              <Button
                size="large"
                id={BUTTON_IDS.devicesExportExcel}
                onClick={handleExportExcel}
                disabled={isFetchingFleets || isFetchingIQCameraDevicesConfig}
              >
                {t('Common.ExporttoExcel')}
              </Button>
            </BtnsWithBulkEdit>
          </div>
        </HeaderPageWrapper>
        <div style={{ display: 'flex', background: '#f7f8f9' }}>
          <FilterWrapper>
            <AntSearchbar onFilter={value => setFilterText(value)} />
            <AntMultiselect
              title={companiesSelectTitle}
              onFilter={v => setFilterCompanies(v)}
              data={filterCompanies}
            />
            <AntMultiselect
              title={fleetSelectTitle}
              data={filterFleets}
              onFilter={v => setFilterFleets(v)}
            />
            <AntMultiselect
              title={typeSelectTitle}
              data={filterTypes}
              onFilter={handleTypeFilter}
            />
            <AntMultiselect
              title={modelSelectTitle}
              data={modelOptions}
              onFilter={v => setFilterModelOptions(v)}
            />

            {canControlGpioConfiguration && filterTab !== 'deleted' && (
              <AntMultiselect
                title={
                  filterGpioConfig?.some(value => !value.checked)
                    ? t('Common.GpioConfigs')
                    : t('Common.AllGpioConfigs')
                }
                data={filterGpioConfig}
                onFilter={v => setFilterGpioConfig(v)}
              />
            )}
          </FilterWrapper>
          <label
            style={{
              display: 'flex',
              width: '100%',
              marginBottom: 0,
              paddingRight: '20px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              minHeight: '52px'
            }}
          >
            {devices.length} {devices.length === 1 ? t('Devices.Device') : t('Devices.Devices')}
          </label>
        </div>
        <div style={{ flex: '1 0 0' }}>
          {isBulkEditing ? (
            <DevicesBulkEditTable
              isLoading={isFetchingFleets}
              {...tableProps}
              isBulkAssigningAgreement={isBulkAssigningAgreement}
            />
          ) : (
            <DevicesTable
              devices={devices}
              isLoading={isFetchingFleets}
              handleDeleteAction={handleDeleteAction}
              typeOfEntityToDelete={t('Common.device')}
              setTableRef={setTableRef}
              handleAction={action => handleAction(action)}
              filterTab={filterTab}
              getDeviceModelName={getDeviceModelName}
            />
          )}
        </div>
      </ContainerPageWrapper>
    </>
  );
};

export default Devices;
