import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { debounce, isEqual, toLower, sortBy } from 'lodash';

import { useCurrentCompany, useSubCompanies } from 'features/company/companySlice';
import {
  updateCameraHealthOverviewFilter,
  useCameraHealthEventTypes,
  useCurrentCompanyIQCameras
} from 'features/camera/cameraSlice';
import { fetchCameraHealthEvents } from 'features/camera/cameraApi';
import { useLocalization } from 'features/localization/localizationSlice';
import { useTimer } from 'utils/hooks/useTimer';
import {
  useUserManageColumns,
  DB_Registered_Pref,
  ManageColumnsModal
} from 'features/user_manage_columns';
import { prepareDataForMultiselect } from 'utils/filters';
import { format } from 'utils/dates';

import { DatePicker } from 'components/ant';
import AntSearchbar from 'components/form/antSearchbar/AntSearchbar';
import AntMultiselect from 'components/form/antMultiselect/AntMultiselect';
import { COLS, DeviceList } from './DeviceList';
import { DeviceListExportButton } from './DeviceListExportButton';

import styles from './CameraHealthOverview.module.scss';

const columns = [
  COLS.SortableDeviceName,
  COLS.VehicleName,
  COLS.HealthMetric,
  COLS.LastResponse,
  COLS.LastLocation
];

const CHECK_REST_TIME = 45 * 60 * 1000,
  CHECKING_INTERVAL = 15 * 60 * 1000;

export const CameraHealthOverview = ({
  checkRestTime = CHECK_REST_TIME,
  checkInterval = CHECKING_INTERVAL
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const localization = useLocalization();

  const { visibleColumns, manageModalProps } = useUserManageColumns({
    columns,
    pref: DB_Registered_Pref.camera_health_overview,
    manageColKey: COLS.LastLocation.key
  });

  const { updateFilter, isFilterReady, filter, allIQCameras } = useCompanyOverviewFilter();

  const filterOptions = useMemo(() => {
    const {
        onSearch,
        onFilterCompanies,
        onFilterFleets,
        onFilterVehicles,
        onFilterDevices,
        onFilterEventTypes,
        onDateChanged
      } = updateFilter,
      dataStore = filter?.filterTree;

    const filterOptions = [
      {
        key: 'searchFilter',
        id: 'overviewSearch',
        component: (
          <AntSearchbar
            className={styles.search}
            onFilter={text => debounce(onSearch, 150).call(this, text)}
            maxLength={50}
            value={dataStore?.search}
          />
        )
      },
      {
        key: 'companyFilter',
        id: 'overviewCompanyFilter',
        component: (
          <AntMultiselect
            className={styles.companies}
            title={
              !dataStore?.companyOptions?.some(company => !company.checked)
                ? t('Common.AllCompanies')
                : t('Common.Companies')
            }
            data={dataStore?.companyOptions}
            onFilter={onFilterCompanies}
          />
        )
      },
      {
        key: 'fleetFilter',
        id: 'overviewFleetFilter',
        component: (
          <AntMultiselect
            className={styles.fleets}
            title={
              !dataStore?.fleetOptions?.some(value => !value.checked)
                ? t('Common.AllFleets')
                : t('Common.Fleets')
            }
            data={dataStore?.fleetOptions}
            onFilter={onFilterFleets}
          />
        )
      },
      {
        key: 'vehicleFilter',
        id: 'overviewVehicleFilter',
        component: (
          <AntMultiselect
            className={styles.vehicles}
            title={
              !dataStore?.vehicleOptions?.find(value => !value.checked)
                ? t('VehicleMntSchedules.AllVehicles')
                : t('Common.Vehicles')
            }
            data={dataStore?.vehicleOptions}
            onFilter={onFilterVehicles}
          />
        )
      },
      {
        key: 'deviceFilter',
        id: 'overviewDeviceFilter',
        component: (
          <AntMultiselect
            className={styles.devices}
            title={
              !dataStore?.deviceOptions?.find(value => !value.checked)
                ? t('Common.AllDevices')
                : t('Common.Devices')
            }
            data={dataStore?.deviceOptions}
            onFilter={onFilterDevices}
          />
        )
      },
      {
        key: 'eventTypeFilter',
        id: 'overviewEventTypeFilter',
        component: (
          <AntMultiselect
            title={
              !dataStore?.eventTypeOptions?.some(e => !e.checked)
                ? `${t('Common.All')} ${t('CameraHealth.Health Event Types')}`
                : t('CameraHealth.Health Event Types')
            }
            className={styles.eventTypeFilter}
            data={dataStore?.eventTypeOptions}
            onFilter={onFilterEventTypes}
          />
        )
      },
      {
        key: 'datePicker',
        id: 'overviewDatePicker',
        component: (
          <DatePicker
            disabledDate={current =>
              current <
                moment()
                  .subtract(12, 'month')
                  .startOf('month') || current >= moment().endOf('month')
            }
            allowClear={false}
            picker={'month'}
            className={styles.datePicker}
            format={'MMM YYYY'}
            defaultValue={dataStore?.selectedDateRange[0]}
            onChange={date =>
              onDateChanged([moment(date).startOf('month'), moment(date).endOf('month')])
            }
          />
        ),
        config: {}
      }
    ];
    return filterOptions;
  }, [t, updateFilter, filter?.filterTree]);

  const reqCtxRef = useRef({
    request: null
  });

  const [isListLoading, setIsListLoading] = useState(true);

  const [diagnosticDevices, setDiagnosticDevices] = useState(null);

  const fetchDevicesSummary = useCallback(
    (silence = true) => {
      if (!isFilterReady) {
        return;
      }
      const curCtxParams = {
        deviceIds: filter.deviceIds,
        healthEventTypes: filter.eventTypes,
        startTime: filter.selectedDateRange?.[0].format(),
        endTime: filter.selectedDateRange?.[1].format()
      };

      const onReqDone = error => {
        if (reqCtxRef.current.request) {
          reqCtxRef.current.request.abort();
        }
        reqCtxRef.current.request = null;
        reqCtxRef.current.fetchTime = moment().valueOf();
        reqCtxRef.current.error = error || null;
        reqCtxRef.current.ctxParams = curCtxParams;
        setIsListLoading(false);
      };

      if (curCtxParams.deviceIds?.length && curCtxParams.healthEventTypes?.length) {
        let sameParams = false,
          reqCtx = reqCtxRef.current;
        if (reqCtx?.ctxParams) {
          const {
            deviceIds: prevIds,
            healthEventTypes: prevTypes,
            startTime: prevStart,
            endTime: prevEnd
          } = reqCtx.ctxParams;
          const {
            deviceIds: curIds,
            healthEventTypes: curTypes,
            startTime: curStart,
            endTime: curEnd
          } = curCtxParams;
          sameParams =
            isEqual(new Set(prevIds), new Set(curIds)) &&
            isEqual(new Set(prevTypes), new Set(curTypes)) &&
            isEqual(prevStart, curStart) &&
            isEqual(prevEnd, curEnd);
        }
        if (
          !reqCtx ||
          (!isListLoading &&
            (!reqCtx.fetchTime || moment().valueOf() - reqCtx.fetchTime >= checkRestTime)) ||
          !sameParams
        ) {
          if (reqCtx.request) {
            reqCtx.request.abort();
            reqCtx.request = null;
          }
          reqCtx.ctxParams = curCtxParams;
          reqCtx.request = dispatch(
            fetchCameraHealthEvents({
              ...curCtxParams,
              onSuccess: data => {
                onReqDone();
                const diagnosticDevices = (data || []).filter(d => !!d.healthEventTypes?.length);
                setDiagnosticDevices(
                  diagnosticDevices.map(d => {
                    const device = allIQCameras.find(
                      iqDevice => Number(iqDevice.id) === Number(d.deviceId)
                    );
                    return {
                      deviceId: d.deviceId,
                      deviceName: device?.name || d.deviceName,
                      vehicleId: device?.vehicle?.id || d.vehicleId,
                      vehicleName: device?.vehicle?.name,
                      healthEventTypes: d.healthEventTypes.map(type => ({
                        type: type?.eventType || type,
                        timestamp: type?.eventTime
                          ? format(
                              moment.utc(type.eventTime).toISOString(true),
                              localization.formats.time.formats.dby_imp
                            )
                          : ''
                      })),
                      lastResponse: d.lastCommsAt
                        ? format(new Date(d.lastCommsAt), localization.formats.time.formats.dby_imp)
                        : '',
                      lastLocation: d.lastLocation || ''
                    };
                  })
                );
              },
              onError: errMsg => {
                onReqDone(errMsg);
                setDiagnosticDevices([]);
              }
            })
          );
          const fetchData = async () => {
            try {
              if (!silence) {
                setIsListLoading(true);
              }
              await reqCtx.request;
            } catch (e) {
              onReqDone(e);
            }
          };
          fetchData();
        }
      } else {
        onReqDone();
        setDiagnosticDevices([]);
      }
    },
    [
      dispatch,
      reqCtxRef,
      isFilterReady,
      allIQCameras,
      filter?.deviceIds,
      filter?.eventTypes,
      filter?.selectedDateRange,
      localization?.formats.time.formats.dby_imp,
      isListLoading
    ]
  );

  useEffect(() => fetchDevicesSummary(false), [fetchDevicesSummary]);

  useTimer(checkInterval, fetchDevicesSummary);

  const searchedDiagnosticDevices = useMemo(() => {
    if (filter?.search) {
      const searchArray = filter.search.split(' ');
      return diagnosticDevices?.filter(device => {
        let validDevice = true;
        searchArray.forEach(splitValue => {
          validDevice =
            validDevice &&
            (toLower(device.deviceName).indexOf(toLower(splitValue)) > -1 ||
              toLower(device.vehicleName).indexOf(toLower(splitValue)) > -1);
        });
        return validDevice;
      });
    }

    return diagnosticDevices || [];
  }, [diagnosticDevices, filter?.search]);

  const actionOptions = useMemo(
    () => [
      {
        key: 'export',
        component: (
          <DeviceListExportButton
            id="exportDeviceList"
            devices={searchedDiagnosticDevices}
            columns={visibleColumns}
            fileName={`${t('Home.CameraHealth')} - ${currentCompany?.name || ''}`}
            formater={({ columnName, columnValue, columnDataIndex }) => {
              if (columnDataIndex === COLS.HealthMetric.dataIndex) {
                return {
                  columnName,
                  columnValue: columnValue.map(
                    ({ type, timestamp }) =>
                      `${t([`CameraHealth.Event.${type}`, type])} (${timestamp})`
                  )
                };
              } else if (columnDataIndex === COLS.LastLocation.dataIndex) {
                return { columnName: COLS.LastLocation.title, columnValue };
              } else {
                return { columnName, columnValue };
              }
            }}
          />
        )
      }
    ],
    [t, searchedDiagnosticDevices, currentCompany?.name, visibleColumns]
  );

  return (
    <>
      <DeviceList
        filterOptions={filterOptions}
        devices={searchedDiagnosticDevices}
        isFilterLoading={!isFilterReady}
        isListLoading={isListLoading}
        columns={visibleColumns}
        actionOptions={actionOptions}
        pagination={false}
        className={styles.list}
      />
      <ManageColumnsModal {...manageModalProps} />
    </>
  );
};

const useCompanyOverviewFilter = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    currentCompany,
    isFetching,
    allFleets,
    allIQCameras,
    allVehiclesWithIQCamera
  } = useCurrentCompanyIQCameras();
  const subCompanies = useSubCompanies();
  const { types, isFetching: isFetchingEventTypes } = useCameraHealthEventTypes();
  const eventTypes = useMemo(() => {
    const allowedCodes = [
      'RoadCameraLensCovered',
      'DriverCameraLensCovered',
      'PowerCutAlert',
      'ExternalStorageAvailable',
      'DataNotUploading',
      'MissingDailySnapshot',
      'DrivingWithIgnitionOFF',
      'DeviceOffline'
    ];
    return types?.filter(type => allowedCodes.some(allowed => allowed === type.code));
  }, [types]);

  const getFleetOptions = fleets => {
    const fleetsList = fleets?.map(f => ({
      id: f.id || Number.MAX_SAFE_INTEGER,
      name: f.id ? f.name : t('Common.NoFleet')
    }));
    return prepareDataForMultiselect(sortBy(fleetsList, 'name'), t('Common.AllFleets'), null);
  };

  const currentCompanyFilter = useSelector(
    state => state.cameraInfo.cameraHealth?.overviewFilter?.[currentCompany?.id]
  );

  useEffect(() => {
    if (
      !isFetching &&
      !isFetchingEventTypes &&
      currentCompany?.id &&
      !(
        currentCompanyFilter?.filterTree?.initialized && !currentCompanyFilter?.filterTree?.pristine
      )
    ) {
      dispatch(
        updateCameraHealthOverviewFilter({
          companyId: currentCompany?.id,
          multiple: true,
          filterBy: [
            ['search', null],
            ['deviceIds', allIQCameras.map(d => d.id)],
            ['eventTypes', eventTypes?.map(type => type.code) || []],
            [
              'selectedDateRange',
              [
                moment()
                  .startOf('month')
                  .startOf('day'),
                moment()
                  .endOf('month')
                  .endOf('day')
              ]
            ]
          ],
          filterTree: {
            pristine: true,
            initialized: true,
            companyOptions: prepareDataForMultiselect(
              sortBy(subCompanies, 'name'),
              t('Common.AllCompanies'),
              null
            ),
            fleetOptions: getFleetOptions(allFleets),
            vehicleOptions: prepareDataForMultiselect(
              sortBy(allVehiclesWithIQCamera, 'name'),
              t('Common.AllVehicles'),
              null
            ),
            deviceOptions: prepareDataForMultiselect(
              sortBy(allIQCameras, 'name'),
              t('Common.AllDevices'),
              null
            ),
            eventTypeOptions: prepareDataForMultiselect(
              sortBy(
                eventTypes?.map(type => ({
                  ...type,
                  id: type.code,
                  name: t(`CameraHealth.Event.${type.code}`)
                })),
                'name'
              ),
              `${t('Common.All')} ${t('CameraHealth.Health Event Types')}`,
              null
            )
          }
        })
      );
    }
  }, [
    currentCompany?.id,
    subCompanies,
    allFleets,
    allVehiclesWithIQCamera,
    allIQCameras,
    eventTypes,
    isFetching,
    isFetchingEventTypes,
    currentCompanyFilter?.filterTree?.pristine
  ]);

  const updateFilter = useMemo(() => {
    const companyId = currentCompany?.id,
      prev = currentCompanyFilter?.filterTree;
    return {
      onSearch: debouncedSearchText => {
        dispatch(
          updateCameraHealthOverviewFilter({
            companyId,
            filterBy: ['search', debouncedSearchText],
            filterTree: {
              search: debouncedSearchText
            }
          })
        );
      },
      onFilterCompanies: companyOptions => {
        const selectedCompanies = companyOptions?.reduce((v, c) => {
          v[c.id] = c.checked;
          return v;
        }, {});
        const fleetOptions = getFleetOptions(
          allFleets?.filter(f => selectedCompanies[f.company?.id] || !f.id) || []
        );
        const vehicleOptions = prepareDataForMultiselect(
          sortBy(
            allVehiclesWithIQCamera?.filter(v => selectedCompanies[v.company?.id]) || [],
            'name'
          ),
          t('Common.AllVehicles'),
          null
        );
        const cameras = allIQCameras?.filter(d => selectedCompanies[d.company?.id]) || [];
        const deviceOptions = prepareDataForMultiselect(
          sortBy(cameras, 'name'),
          t('Common.AllDevices'),
          null
        );
        dispatch(
          updateCameraHealthOverviewFilter({
            companyId,
            filterBy: ['deviceIds', cameras.map(d => d.id)],
            filterTree: {
              companyOptions,
              fleetOptions,
              vehicleOptions,
              deviceOptions
            }
          })
        );
      },
      onFilterFleets: fleetOptions => {
        let cameras = [];
        const selectedCompanies = prev.companyOptions?.reduce((o, c) => {
          o[c.id] = c.checked;
          return o;
        }, {});

        const selectedFleets = fleetOptions?.reduce((o, f) => {
          o[f.id] = f.checked;
          return o;
        }, {});

        const vehicleOptions = prepareDataForMultiselect(
          sortBy(
            allVehiclesWithIQCamera?.filter(
              v =>
                v.fleets?.some(f => selectedFleets[f.id]) ||
                (selectedFleets[Number.MAX_SAFE_INTEGER] &&
                  selectedCompanies[v.company?.id] &&
                  v.fleets?.some(f => !f.id))
            ) || [],
            'name'
          ),
          t('Common.AllVehicles'),
          null
        );

        cameras =
          allIQCameras?.filter(
            d =>
              d.fleetInfo?.some(f => selectedFleets[f.id]) ||
              (selectedFleets[Number.MAX_SAFE_INTEGER] &&
                selectedCompanies[d.company?.id] &&
                d.fleetInfo?.some(f => !f.id))
          ) || [];

        const deviceOptions = prepareDataForMultiselect(
          sortBy(cameras, 'name'),
          t('Common.AllDevices'),
          null
        );
        dispatch(
          updateCameraHealthOverviewFilter({
            companyId,
            filterBy: ['deviceIds', cameras.map(d => d.id)],
            filterTree: {
              fleetOptions,
              vehicleOptions,
              deviceOptions
            }
          })
        );
      },
      onFilterVehicles: vehicleOptions => {
        let cameras = [];
        const selectedCompanies = prev.companyOptions?.reduce((o, c) => {
          o[c.id] = c.checked;
          return o;
        }, {});

        const selectedFleets = prev.fleetOptions?.reduce((o, f) => {
          o[f.id] = f.checked;
          return o;
        }, {});

        const selectedVehicles = vehicleOptions?.reduce((o, c) => {
          o[c.id] = c.checked;
          return o;
        }, {});

        const isSelectAllVehicles = Object.values(selectedVehicles).every(v => !!v),
          isUnselectAllVehicles = Object.values(selectedVehicles).every(v => !v);
        cameras =
          allIQCameras?.filter(d => {
            const inInUpperScope = device =>
              device.fleetInfo?.some(f => selectedFleets[f.id]) ||
              (selectedFleets[Number.MAX_SAFE_INTEGER] &&
                selectedCompanies[device.company?.id] &&
                device.fleetInfo?.some(f => !f.id));
            if (isSelectAllVehicles) {
              return inInUpperScope(d);
            } else if (isUnselectAllVehicles) {
              return !d.vehicle?.id && inInUpperScope(d);
            } else {
              return d.vehicle?.id && selectedVehicles[d.vehicle?.id];
            }
          }) || [];

        const deviceOptions = prepareDataForMultiselect(
          sortBy(cameras, 'name'),
          t('Common.AllDevices'),
          null
        );
        dispatch(
          updateCameraHealthOverviewFilter({
            companyId,
            filterBy: ['deviceIds', cameras.map(d => d.id)],
            filterTree: {
              vehicleOptions,
              deviceOptions
            }
          })
        );
      },
      onFilterDevices: deviceOptions => {
        const selectedDevices = deviceOptions?.reduce((o, c) => {
          o[c.id] = c.checked;
          return o;
        }, {});
        const cameras = allIQCameras?.filter(d => selectedDevices[d.id]) || [];
        dispatch(
          updateCameraHealthOverviewFilter({
            companyId,
            filterBy: ['deviceIds', cameras.map(d => d.id)],
            filterTree: {
              deviceOptions
            }
          })
        );
      },
      onFilterEventTypes: eventTypeOptions => {
        const selectedTypes = eventTypes?.filter(type =>
          eventTypeOptions?.some(opt => opt.checked && opt.id === type.code)
        );
        dispatch(
          updateCameraHealthOverviewFilter({
            companyId,
            filterBy: ['eventTypes', selectedTypes.map(d => d.code)],
            filterTree: {
              eventTypeOptions
            }
          })
        );
      },
      onDateChanged: selectedDateRange => {
        dispatch(
          updateCameraHealthOverviewFilter({
            companyId,
            filterBy: ['selectedDateRange', selectedDateRange],
            filterTree: {
              selectedDateRange
            }
          })
        );
      }
    };
  }, [
    allFleets,
    allVehiclesWithIQCamera,
    allIQCameras,
    eventTypes,
    currentCompany?.id,
    currentCompanyFilter?.filterTree
  ]);

  return {
    filter: currentCompanyFilter,
    updateFilter,
    allIQCameras,
    isFilterReady:
      !isFetching && !isFetchingEventTypes && currentCompany?.id && currentCompanyFilter
  };
};
