import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { API_PATH } from '../../config';
import { ApiClient } from '../../nextgen_api/index';

export const installationAuditLog_Data = {
  installationDetailsByDevice: null,
  installationDetailsByUser: null,
  installationDetailsByCompany: null,
  installationLogsViewData: null,
  viewByType: 'vehicle',
  installationViewPhotosByEventId: null,
  loading: false
};

const InstallationLogsSlice = createSlice({
  name: 'installationLogsSData',
  initialState: installationAuditLog_Data,
  reducers: {
    fetchinstallationDetailsByCompanyStart(state, { payload }) {
      state.loading = true;
      state.installationDetailsByCompany = null;
    },
    fetchinstallationDetailsByCompanySucceeded(state, { payload }) {
      const { data } = payload;
      state.loading = false;
      state.installationDetailsByCompany = data;
    },
    fetchinstallationDetailsByCompanyFailed(state, { payload }) {
      state.loading = false;
      state.installationDetailsByCompany = null;
    },
    fetchinstallationDetailsByDeviceStart(state, { payload }) {
      state.installationDetailsByDevice = null;
    },
    fetchinstallationDetailsByDeviceSucceeded(state, { payload }) {
      const { data } = payload;
      state.installationDetailsByDevice = data;
    },
    fetchinstallationDetailsByDeviceFailed(state, { payload }) {
      state.installationDetailsByDevice = null;
    },
    fetchinstallationDetailsByUserStart(state, { payload }) {
      state.installationDetailsByUser = null;
    },
    fetchinstallationDetailsByUserSucceeded(state, { payload }) {
      const { data } = payload;
      state.installationDetailsByUser = data;
    },
    fetchinstallationDetailsByUserFailed(state, { payload }) {
      state.installationDetailsByUser = null;
    },
    setViewByTypeStart(state) {
      state.viewByType = 'vehicle';
    },
    setViewByTypeSuccess(state, { payload }) {
      state.viewByType = payload;
    },
    // setInstallationLogsViewDataStart(state) {
    //   state.installationLogsViewData = {};
    // },
    setInstallationLogsViewDataSuccess(state, { payload }) {
      state.installationLogsViewData = payload;
    },
    fetchInstallationViewPhotosByEventIdRequest(state, { payload }) {
      state.loading = true;
      state.installationViewPhotosByEventId = null;
    },
    fetchInstallationViewPhotosByEventIdSuccess(state, { payload }) {
      state.loading = false;
      const { data } = payload;
      state.installationViewPhotosByEventId = data;
    },
    fetchInstallationViewPhotosByEventIdFailure(state, { payload }) {
      state.loading = false;
      state.installationViewPhotosByEventId = null;
    }
  }
});
export const {
  fetchinstallationDetailsByCompanyStart,
  fetchinstallationDetailsByCompanySucceeded,
  fetchinstallationDetailsByCompanyFailed,
  fetchinstallationDetailsByDeviceStart,
  fetchinstallationDetailsByDeviceSucceeded,
  fetchinstallationDetailsByDeviceFailed,
  fetchinstallationDetailsByUserStart,
  fetchinstallationDetailsByUserSucceeded,
  fetchinstallationDetailsByUserFailed,
  setViewByTypeStart,
  setViewByTypeSuccess,
  // setInstallationLogsViewDataStart,
  setInstallationLogsViewDataSuccess,
  fetchInstallationViewPhotosByEventIdRequest,
  fetchInstallationViewPhotosByEventIdSuccess,
  fetchInstallationViewPhotosByEventIdFailure
} = InstallationLogsSlice.actions;

export const fetchinstallationDetailsByCompany = (fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchinstallationDetailsByCompanyStart());

  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/installations/jobs?company_id=${companyId}&from=${fromDate}&to=${toDate}&embed=vehicle,device,user,company,attachment`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  try {
    const data = await callPromise;
    dispatch(fetchinstallationDetailsByCompanySucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchinstallationDetailsByCompanyFailed(payload));
  }
};
export const fetchinstallationDetailsByDevice = (fromDate, toDate) => async (
  dispatch,
  getState
) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchinstallationDetailsByDeviceStart());

  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/installations/jobs?company_id=${companyId}&from=${fromDate}&to=${toDate}&embed=device`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  try {
    const data = await callPromise;
    // console.log("data====", data)
    dispatch(fetchinstallationDetailsByDeviceSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchinstallationDetailsByDeviceFailed(payload));
  }
};
export const fetchinstallationDetailsByUser = (fromDate, toDate) => async (dispatch, getState) => {
  const userKey = getState().user?.current?.auth?.key;
  const companyId = getState().companies?.current?.id;

  dispatch(fetchinstallationDetailsByUserStart());

  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;

    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };

    var sentinelUrl = `/installations/jobs?company_id=${companyId}&from=${fromDate}&to=${toDate}&embed=user`;

    apiClient.callApi(
      sentinelUrl,
      'GET',
      {},
      {},
      {},
      {},
      {},
      [],
      [],
      [],
      null,
      null,
      (err, data, resp) => {
        if (err && (resp == null || resp.status !== 200)) {
          reject(err);
        } else {
          resolve(resp.body);
        }
      }
    );
  });
  try {
    const data = await callPromise;
    dispatch(fetchinstallationDetailsByUserSucceeded({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchinstallationDetailsByUserFailed(payload));
  }
};

export const fetchInstallationViewPhotosByEventId = eventId => async (dispatch, state) => {
  const userKey = state().user?.current?.auth?.key;
  dispatch(fetchInstallationViewPhotosByEventIdRequest());
  const callPromise = new Promise((resolve, reject) => {
    const apiClient = new ApiClient();
    apiClient.basePath = API_PATH;
    apiClient.defaultHeaders = {
      Authorization: `Token token="${userKey}"`
    };
    const url = `/installations/jobs/${eventId}?embed=vehicle,device,user,company,attachment`;
    apiClient.callApi(url, 'GET', {}, {}, {}, {}, {}, [], [], [], null, null, (err, data, resp) => {
      if (err && (resp == null || resp.status !== 200)) {
        reject(err);
      } else {
        resolve(resp.body);
      }
    });
  });
  try {
    const data = await callPromise;
    dispatch(fetchInstallationViewPhotosByEventIdSuccess({ data: data }));
  } catch (err) {
    const payload = { error: err.toString() };
    dispatch(fetchInstallationViewPhotosByEventIdFailure(payload));
  }
};

export const setViewByType = type => (dispatch, getState) => {
  dispatch(setViewByTypeSuccess(type));
};
export const setInstallationLogsViewData = payload => (dispatch, getState) => {
  dispatch(setInstallationLogsViewDataSuccess(payload));
};
export const useInstallationLogsDatabyCompany = () => {
  const { loading, installationDetailsByCompany } =
    useSelector(state => state?.installationLogsData) || {};
  return { loading, installationLogsDataByCompany: installationDetailsByCompany };
};
export const useInstallationLogsDatabyDevice = () => {
  const data = useSelector(state => state?.installationLogsData?.installationDetailsByDevice);
  return data;
};
export const useInstallationLogsDatabyUser = () => {
  const data = useSelector(state => state?.installationLogsData?.installationDetailsByUser);
  return data;
};
export const useSetViewByType = () => {
  const data = useSelector(state => state?.installationLogsData?.viewByType);
  return data;
};
export const useInstallationLogsViewData = () => {
  const data = useSelector(state => state?.installationLogsData?.installationLogsViewData);
  return data;
};

export const useInstallationViewPhotosByEventId = () => {
  const { loading, installationViewPhotosByEventId } =
    useSelector(state => state?.installationLogsData) || {};
  return { loading, data: installationViewPhotosByEventId };
};
export default InstallationLogsSlice.reducer;
