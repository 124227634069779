import { getRegistrationStateLabel } from 'features/localization/localization';
import i18n from 'i18nextConfig';
import { OS_LABELS, deviceTypeModelTranslate } from './constants';
import moment from 'moment';
import { CameraModelConfig, isIQCamera } from 'features/camera/CameraModelConfig';

const getOSLabel = device => {
  switch (device?.type.name) {
    case OS_LABELS.HERMES:
      if (device?.stats?.devVersion) {
        try {
          return JSON.parse(device?.stats?.devVersion)?.HERMES;
        } catch (error) {
          console.error(error);
          break;
        }
      }
    case OS_LABELS.IVU:
      if (device?.stats?.devVersion) {
        try {
          return JSON.parse(device?.stats?.devVersion)?.OS;
        } catch (error) {
          console.error(error);
          break;
        }
      }
    default:
      if (device.stats?.devVersion) {
        const objectStats = JSON.parse(device.stats.devVersion);
        if (objectStats?.OSVersion) {
          return objectStats.OSVersion;
        }
        if (objectStats?.AndroidVersion) {
          return objectStats.BuildNumber
            ? `${objectStats.AndroidVersion} (${objectStats.BuildNumber})`
            : objectStats.AndroidVersion;
        }
      }
  }

  return '';
};

const getAppVersion = deviceStats => {
  if (deviceStats?.devAppVersion) {
    return deviceStats?.devAppVersion;
  }
  if (deviceStats?.devVersion) {
    return deviceStats?.devVersion;
  }
  return '';
};

export const prepareFileForExcelExport = data => {
  const {
    devices,
    vehicles,
    companies,
    countryCode,
    devicesStats,
    localization,
    dateFormat,
    iqCameraDevicesConfig,
    canControlGpioConfiguration,
    getDeviceModelName = modelName => modelName
  } = data;
  const isAnyIqCameraDevices = devices.some(isIQCamera);

  const rows = devices
    ? devices.map(device => {
        const company = companies.find(company => company.id === device.companyId);
        const deviceStats = devicesStats.find(
          stats => parseInt(stats.deviceId, 10) === parseInt(device.id, 10)
        );
        const vehicle = vehicles.find(vehicle => vehicle.id === device.vehicle?.id);
        const deviceProperties = {
          type: device?.type,
          stats: deviceStats
        };

        let row = {
          [i18n.t('Devices.ActualForm.NameLabel')]: device?.name || '',
          [i18n.t('Devices.ActualForm.CompanyLabel')]: company?.name || '',
          [i18n.t('Devices.ActualForm.VehicleLabel')]: vehicle?.name || 'No Vehicle',
          [getRegistrationStateLabel(countryCode)]: vehicle?.registrationState || '',
          [i18n.t('Devices.ActualForm.RegistrationLabel')]: vehicle?.registration || '',
          [i18n.t('Devices.ActualForm.FleetLabel')]:
            device?.fleetInfo?.map(fleet => fleet.name).join() || '',
          [i18n.t('Devices.View.IAPID')]:
            (deviceStats && deviceStats.devInfo && JSON.parse(deviceStats.devInfo).IAPID) || '',
          IVUID:
            (deviceStats && deviceStats.devInfo && JSON.parse(deviceStats.devInfo).IVUID) || '',
          DID: device.did || '',
          [i18n.t('Devices.ActualForm.ImeiLabel')]: device.imei || '',
          [i18n.t('Devices.ActualForm.SerialNumberLabel')]: device.serialNumber || '',
          [i18n.t('Devices.ActualForm.SAPEquipmentID')]: device.sapEquipmentId || '',
          [i18n.t('Devices.ActualForm.SimLabel')]: device.sim || '',
          [i18n.t('Devices.ActualForm.TypeLabel')]: deviceTypeModelTranslate(
            device.type?.name || ''
          ),
          [i18n.t('Devices.ActualForm.ModelLabel')]: deviceTypeModelTranslate(
            getDeviceModelName(device.model?.name)
          ),
          [i18n.t('Devices.View.OdometerLabel')]:
            (deviceStats && localization.convertDistance(deviceStats.odometer)) || '',
          [i18n.t('Devices.ActualForm.NotesLabel')]: device.note || '',
          [i18n.t('Devices.View.ReleaseVersionLabel')]: device.releaseVersion || '',
          [i18n.t('Devices.View.AppVersionLabel')]: getAppVersion(deviceStats),
          [i18n.t('Devices.View.SmartNavInfoLabel')]:
            (deviceStats && deviceStats.smartNavInfo) || '',
          [i18n.t('Devices.View.OperatingSystemLabel')]: getOSLabel(deviceProperties),
          [i18n.t('Devices.View.SquarellVersionLabel')]:
            (deviceStats && deviceStats.squarellVersion) || '',
          [i18n.t('Devices.View.SquarellInfoLabel')]:
            (deviceStats && deviceStats.squarellInfo) || '',
          [i18n.t('Devices.View.LastLocation')]: (deviceStats && deviceStats.location) || '',
          [i18n.t('Devices.View.LastContact')]:
            (deviceStats &&
              deviceStats.lastCommsAt &&
              moment(deviceStats.lastCommsAt).format(dateFormat)) ||
            '',
          [i18n.t('Devices.View.CreatedAtLabel')]:
            (device && device.createdAt && moment(device.createdAt).format(dateFormat)) || '',
          [i18n.t('Devices.View.UpdatedAtLabel')]:
            (device && device.updatedAt && moment(device.updatedAt).format(dateFormat)) || ''
        };

        if (canControlGpioConfiguration) {
          row[i18n.t('Devices.ActualForm.GpioConfig')] = device.gpioTemplate?.name || '';
        }

        if (isAnyIqCameraDevices) {
          const isIQCameraDevice = isIQCamera(device);
          row[i18n.t('Devices.ActualForm.CameraSensitivity')] = isIQCameraDevice
            ? iqCameraDevicesConfig?.[device.id]?.config?.dutyType
              ? i18n.t(
                  `CompanyConfig.IQCamera.${iqCameraDevicesConfig[device.id].config?.dutyType}`
                )
              : ''
            : i18n.t('Common.NA');
          row[i18n.t('Devices.ActualForm.DeviceVolumeSetting')] = isIQCameraDevice
            ? iqCameraDevicesConfig[device.id].config?.deviceAudioVolume || '0'
            : i18n.t('Common.NA');
        }

        return row;
      })
    : [];

  return rows;
};

export const bytesToMB = bytes => {
  let mb = 0;
  try {
    mb = Number(bytes) / 1000000;
  } catch (error) {}
  return Number(isNaN(mb) ? 0 : mb.toFixed(2));
};

export const getDataPlanUsage = ({ dataPlan = {}, currentRegionCode }) => {
  const {
    isExhausted: isVRExhausted,
    max: totalVRAllowed,
    remaining: vrRemaining,
    used: vrUsed,
    monthlyRenewOn
  } = CameraModelConfig.IQCamera.getDataPlanUsage({
    dataPlan,
    planKeys: ['DVR_UPLOADED'],
    currentRegionCode
  });
  const {
    remaining: autoVUploadRemaining,
    used: autoVUploadUsed
  } = CameraModelConfig.IQCamera.getDataPlanUsage({
    dataPlan,
    planKeys: ['EVENT_VIDEO_UPLOADED'],
    currentRegionCode
  });
  const planItems = [
    {
      title: [i18n.t('Home.Video Requests')],
      details: `${i18n.t('Devices.View.DataPlan.Remaining')} - ${vrRemaining} / ${i18n.t(
        'Devices.View.DataPlan.Consumed'
      )} - ${vrUsed}`
    },
    {
      title: [i18n.t('CompanyConfig.IQCamera.ForwardCollisionWarning')],
      details: i18n.t('Devices.View.DataPlan.Unlimited')
    },
    {
      title: [i18n.t('Tracking.Events.Harsh Acceleration')],
      details: i18n.t('Devices.View.DataPlan.Unlimited')
    },
    {
      title: [i18n.t('Tracking.Events.Harsh Braking')],
      details: i18n.t('Devices.View.DataPlan.Unlimited')
    },
    {
      title: [i18n.t('Tracking.Events.Harsh Cornering')],
      details: i18n.t('Devices.View.DataPlan.Unlimited')
    },
    {
      title: [i18n.t('Devices.View.DataPlan.DriverInitiate')],
      details: i18n.t('Devices.View.DataPlan.Unlimited')
    },
    {
      title: [i18n.t('Devices.View.DataPlan.LaneDrift')],
      details: i18n.t('Devices.View.DataPlan.AvailableOnRequest')
    },
    {
      title: [
        `${i18n.t('Devices.View.DataPlan.AutoUploadEvents')}:`,
        i18n.t('Devices.View.DataPlan.AutoUploadEventsDesc')
      ],
      details: `${i18n.t('Devices.View.DataPlan.Remaining')} - ${autoVUploadRemaining} / ${i18n.t(
        'Devices.View.DataPlan.Consumed'
      )} - ${autoVUploadUsed}`
    }
  ];
  return {
    planItems,
    warning: isVRExhausted
      ? i18n.t('Devices.View.DataPlan.Exhausted', {
          max: totalVRAllowed,
          date: i18n.t(`Devices.View.DataPlan.MonthlyRenew.Next.${monthlyRenewOn}`)
        })
      : null,
    monthlyRenewOn
  };
};

export const isNewDevice = device => device?.stats?.lastEvent === 'NEW';

// This is duplicated and used in 3 places, we need to move it to a generic place
// like containers/Configuration/CompanyConfig/Utils/helpers and reusit everywhere
// or to containers/Configuration/CompanyConfig/DeviceConfiguration/Utils/helpers
export const inputValidator = item => {
  return {
    number: [
      {
        validator: (_, value) => {
          const num = Number(value);
          const min = item?.min || 0;
          const max = item?.max || 999;

          if (isNaN(Number(value)) || !isFinite(Number(value)) || value === null) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.NumberTypeError')
            );
          }

          if (!Number.isInteger(num)) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.IntegerValue')
            );
          }

          if (num < min) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MinValue', {
                min: `${min}`
              })
            );
          }
          if (num > max) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MaxValue', {
                max: `${max || 999}`
              })
            );
          }

          return Promise.resolve();
        }
      }
    ],
    double: [
      {
        validator: (_, value) => {
          const num = Number(value);
          const min = item?.min || 0;
          const max = item?.max || 999;

          if (!value) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.NumberTypeError')
            );
          }

          if (num < min) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MinValue', {
                min: `${min}`
              })
            );
          }
          if (num > max) {
            return Promise.reject(
              i18n.t('CompanyConfig.DeviceConfigurations.FormValidation.MaxValue', {
                max: `${max || 999}`
              })
            );
          }

          return Promise.resolve();
        }
      }
    ]
  };
};
