import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Form,
  Table,
  Spin,
  InputNumber,
  Alert,
  Modal,
  Space,
  Tooltip
} from 'antd';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React from 'react';

import { BUTTON_IDS } from 'utils/globalConstants';
import { parseErrorMessage } from 'utils/strings';

import {
  useCanEveryCompanyService,
  services,
  FeatureFlag,
  useCanFeatureFlag
} from 'features/permissions';
import { useCurrentCompany } from 'features/company/companySlice';
import { useDeviceTypesList } from 'features/device_types/deviceTypesSlice';
import {
  fetchFleets,
  useDevices,
  useIsFetching,
  useVehiclesFromFleets
} from 'features/fleets/fleetsSlice';
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { openToast } from 'features/toasts/toastsSlice';

import { useGpioRelatedConfigKeys } from 'services/nextgen/ngConfigKeyApi';
import {
  useGetGpioConfigurationTemplatesQuery,
  useAddGpioConfigurationTemplateMutation,
  useUpdateGpioConfigurationTemplateMutation,
  useCurrentCompanyServicesMap
} from 'services/nextgen/ngGpioConfigurationApi';

import { ToastType } from 'components/notifications/toasts/Toast';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';

import { GPIOFormModal } from './GPIOFormModal';
import { GPIOTableModal } from './GPIOTableModal';
import {
  gpioDiginEditColumns,
  gpioDigoutEditColumns,
  PATHS,
  BPM_VPM_KEYS,
  SIREN_KEYS,
  IDLE_KEYS,
  LED_KEYS,
  KEY_TO_CONVERT
} from './constants';
import {
  useConfigMapping,
  enrichWithExtraServiceConfig,
  inputValidator,
  toTableStructure
} from './helpers';
import { useLocalization } from 'features/localization/localizationSlice';
import { UNITS } from 'features/localization/localization';
import { canHistoryGoBack } from 'utils/methods';
import { DevicesAssignmentTable } from '../components/DevicesAssignmentTable';
import { templateTypeEnum } from '../components/constants';
import { HeaderFormSection } from '../components/HeaderFormSection';
import style from './GPIOForm.module.scss';

export const GPIOForm = ({ templateId, action }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const localization = useLocalization();
  const dispatch = useDispatch();
  const currentCompany = useCurrentCompany();
  const [form] = Form.useForm();
  const [gpioList, setGpioList] = useState([]);
  const [editingConfig, setEditingConfig] = useState();
  const [editingIndex, setEditingIndex] = useState(-1);
  const [companyLevel, setCompanyLevel] = useState(false);
  const [previousCompanyLevel, setPreviousCompanyLevel] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysForFilteredDevices, setSelectedRowKeysForFilteredDevices] = useState([]);
  const serviceMap = useCurrentCompanyServicesMap();
  const [modal, contextHolder] = Modal.useModal();
  const [gpioModalVisible, setGpioModalVisible] = useState(false);
  const [gpioModalData, setGpioModalData] = useState();
  const [gpioModalTempateName, setGpioModalTemplateName] = useState();
  const [formValid, setFormValid] = useState(true);

  const [diginData, setDiginData] = useState([]);
  const [digoutData, setDigoutData] = useState([]);

  const isVPMEnabled = useCanEveryCompanyService(services.VPM);
  const isBPMEnabled = useCanEveryCompanyService(services.BPM);
  const isSpeedsirenEnabled = useCanEveryCompanyService(services.SPEEDSIREN);

  const vt202OutputFeature = useCanFeatureFlag({
    featureFlag: FeatureFlag.vt202Output.flag
  });

  const idleBuzzerFeature = useCanFeatureFlag({
    featureFlag: FeatureFlag.idleBuzzer.flag
  });

  const types = useDeviceTypesList();
  const hermes = types.find(type => type.code === 'HERMES');
  const allDevices = useDevices();

  const isFetchingFleets = useIsFetching();
  const { vehicles, isFetchingVehicles } = useVehiclesFromFleets();

  const { data: templateData, isFetching } = useGetGpioConfigurationTemplatesQuery(
    { companyId: currentCompany?.id, embed: 'devices' },
    { skip: currentCompany?.id === undefined }
  );
  const [
    addGpioConfigurationTemplate,
    { isLoading: isAddingTemplating }
  ] = useAddGpioConfigurationTemplateMutation();
  const [
    updateGpioConfigurationTemplate,
    { isLoading: isUpdatingTemplating }
  ] = useUpdateGpioConfigurationTemplateMutation();

  const templates = useMemo(() => {
    const configurationTemplates = (templateData || []).map(i => {
      const template = i.configurationTemplate;
      return { ...template, associations: { devices: i.associatedDevices } };
    });
    return configurationTemplates;
  }, [currentCompany?.id, templateData, isFetching]);

  const deviceTemplateMap = useMemo(() => {
    const map = {};
    templates.forEach(template => {
      template.associations.devices.forEach(device => {
        map[device.id] = template;
      });
    });
    return map;
  }, [templates]);

  const vehicleMap = vehicles.reduce((map, vehicle) => {
    map[vehicle.id] = vehicle;
    return map;
  }, {});
  const companyHermesDevices = useMemo(
    () =>
      allDevices
        .filter(
          device =>
            device?.type?.id === hermes.id &&
            device?.company?.id === currentCompany.id &&
            device?.services?.includes('GPIO')
        )
        .map(device => {
          const template = deviceTemplateMap[device.id];
          const vehicle = device.vehicle ? vehicleMap[device.vehicle.id] : null;
          return { ...device, vehicle: vehicle, template: template };
        }),
    [hermes, currentCompany, allDevices, deviceTemplateMap]
  );
  const [devices, setDevices] = useState(companyHermesDevices);

  useEffect(() => {
    setDevices(companyHermesDevices);
  }, [companyHermesDevices]);

  const constructForm = (service, configs, ioDefinition = 'siren') => {
    if (!configs) {
      return null;
    }
    return {
      name: service,
      type: 'service',
      default: ioDefinition,
      field: configs
        .sort((a, b) =>
          a.key === 'gpio.output' || a.key === 'idle.gpio.output' ? -1 : a.key.localeCompare(b.key)
        )
        .map(s => {
          const defaultValue =
            s.dataType !== 'rquery' && s.defaultValue
              ? JSON.parse(s.defaultValue.replace(/\\"/, '"'))
              : undefined;
          return {
            name: `service.${service}.${s.key.replace(/\./g, '_')}`,
            field: [service, s.key],
            type: s.dataType,
            options: defaultValue?.options,
            default: defaultValue?.default || defaultValue,
            visibleWhen:
              s.key === 'gpio.output' || s.key === 'idle.gpio.output'
                ? undefined
                : diginFormConfig =>
                    diginFormConfig[service]
                      ? diginFormConfig[service]['gpio.output'] === true ||
                        diginFormConfig[service]['idle.gpio.output'] === true
                      : false,
            tooltip: s.description
          };
        })
    };
  };

  const {
    gpioData: gpioConfigs,
    vpmData,
    bpmData,
    speedsirenData,
    isFetching: isFetchingGpioData
  } = useGpioRelatedConfigKeys();

  const vpmConfigs = vpmData?.filter(
    s => BPM_VPM_KEYS.includes(s.key) && (s.key.includes('.pattern.') ? idleBuzzerFeature : true)
  );
  const bpmConfigs = bpmData?.filter(
    s => BPM_VPM_KEYS.includes(s.key) && (s.key.includes('.pattern.') ? idleBuzzerFeature : true)
  );
  const idleConfigs = isVPMEnabled
    ? vpmData?.filter(s => IDLE_KEYS.includes(s.key))
    : bpmData?.filter(s => IDLE_KEYS.includes(s.key));
  const sirenConfigs = speedsirenData?.filter(
    s => SIREN_KEYS.includes(s.key) && (s.key.includes('.pattern.') ? idleBuzzerFeature : true)
  );
  const ledConfigs = speedsirenData?.filter(s => LED_KEYS.includes(s.key));

  const extraConfigs = [];
  if (isVPMEnabled) {
    extraConfigs.push(constructForm('vpm', vpmConfigs));
  }
  if (isBPMEnabled) {
    extraConfigs.push(constructForm('bpm', bpmConfigs));
  }
  if (isSpeedsirenEnabled) {
    extraConfigs.push(
      constructForm(
        'speedsiren',
        sirenConfigs?.filter(s => !s.key.endsWith('.1'))
      )
    );
    extraConfigs.push(constructForm('led', ledConfigs, 'led'));
  }
  if (idleBuzzerFeature && (isVPMEnabled || isBPMEnabled)) {
    extraConfigs.push(constructForm('idle', idleConfigs));
  }

  const {
    inBiasMap,
    inTriggerMap,
    inOccurrenceMap,
    outBiasMap,
    outTriggerMap,
    outOccurrenceMap,
    diginFormConfig,
    digoutFormConfig,
    seatbeltSpeedThresholdDefault,
    channelTypes
  } = useConfigMapping(gpioConfigs);

  const [modalVisible, setModalVisible] = useState(false);

  const diginIODefinition = diginFormConfig.find(cfgField => cfgField.type === 'io');
  const digoutIODefinition = digoutFormConfig.find(cfgField => cfgField.type === 'io');

  const getChannels = channelType => {
    const digoutChannelSize = vt202OutputFeature ? 5 : 4;
    let channelSize = channelTypes.find(c => channelType === c.form + '.' + c.key)?.channels;
    if (!channelSize) {
      if (channelType?.startsWith('digout.')) {
        channelSize = digoutChannelSize;
      } else {
        channelSize = 9;
      }
    }
    const channels = Array.from({ length: channelSize }, (_, index) => ({
      key: `${index + 1}`,
      value: `${index + 1}`
    })).filter(o => {
      const index = gpioList.findIndex(i => i.channel === o.value && i.input === channelType);
      return index < 0 || index === editingIndex;
    });
    return channels;
  };

  const handleViewGpio = record => {
    const gpioConfigs = enrichWithExtraServiceConfig(
      record.template?.configurations,
      serviceMap,
      diginIODefinition,
      digoutIODefinition,
      channelTypes,
      localization
    );
    setGpioModalData(gpioConfigs);
    setGpioModalVisible(true);
    setGpioModalTemplateName(record.template?.name);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleValidate = values => {
    let foundIndex = gpioList.findIndex(
      (item, currentIndex) =>
        item.input === values.input &&
        item.channel === values.channel &&
        currentIndex !== editingIndex
    );
    if (foundIndex > -1) {
      throw new Error(t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Channel Already Exists'));
    }
    if (values.io === 'siren') {
      foundIndex = gpioList.findIndex(
        (item, currentIndex) =>
          item.input === values.input && item.io === 'siren' && currentIndex !== editingIndex
      );
      if (foundIndex > -1) {
        throw new Error(
          t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Siren Output Channel Already Exists')
        );
      }
    }
    if (values.io === 'led') {
      foundIndex = gpioList.findIndex(
        (item, currentIndex) =>
          item.input === values.input && item.io === 'led' && currentIndex !== editingIndex
      );
      if (foundIndex > -1) {
        throw new Error(
          t('CompanyConfig.DeviceConfigurations.GPIOTemplates.LED Output Channel Already Exists')
        );
      }
    }
  };

  const handleSubmit = values => {
    setDirty(true);
    if (editingIndex > -1) {
      const cloned = [...gpioList];
      cloned[editingIndex] = values;
      setGpioList(cloned);
    } else {
      setGpioList([...gpioList, values]);
    }
    setModalVisible(false);
  };

  const handleEdit = record => {
    setEditingIndex(gpioList.indexOf(record));
    setEditingConfig(record);
    setModalVisible(true);
  };

  const handleDelete = record => {
    const newData = gpioList.filter(d => d !== record);
    setGpioList(newData);

    setDirty(true);
  };

  const createServiceConfig = (channel, item, service, suffix = '') => {
    const configurations = [];
    if (item && (item['gpio.output'] || item['idle.gpio.output'])) {
      const keys = Object.keys(item);
      keys.forEach(k => {
        let value;
        if (k === 'gpio.output' || k === 'idle.gpio.output') {
          value = channel;
        } else {
          value = Array.isArray(item[k]) ? JSON.stringify(item[k]) : item[k];
        }
        if (KEY_TO_CONVERT.includes(k)) {
          value = localization.convertSpeedWithUnit(
            Number(value),
            UNITS.KM,
            localization?.formats?.speed?.unit || UNITS.KM,
            0
          );
        }
        configurations.push({
          service: service,
          key: k.startsWith('speed.') ? k : k + suffix,
          value: value
        });
      });
    }
    return configurations;
  };

  const handleSaveForm = async () => {
    let confirmed = true;
    if (previousCompanyLevel === true && companyLevel !== previousCompanyLevel) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.RemovingCompanyDefaultMessage')
      });
    }

    // Count of devices which currently assigned to other template
    const count = selectedRowKeys
      .map(id => deviceTemplateMap[id])
      .filter(t => t && t.id !== templateId).length;
    if (confirmed && count > 0) {
      confirmed = await modal.confirm({
        content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeviceAlreadyAssigned')
      });
    }

    const templateServices = new Set();
    gpioList.forEach(item => {
      if (item.vpm?.['gpio.output']) {
        templateServices.add('VPM');
      }
      if (item.bpm?.['gpio.output']) {
        templateServices.add('BPM');
      }
      if (item.speedsiren?.['gpio.output']) {
        templateServices.add('SPEEDSIREN');
      }
    });

    if (!companyLevel) {
      const serviceArray = [...templateServices];
      const deviceNotFullfiled = [...new Set(selectedRowKeys)]
        .map(id => devices.find(d => d.id === id))
        .filter(d => !serviceArray.every(e => d?.services?.includes(e))).length;
      if (confirmed && deviceNotFullfiled > 0) {
        confirmed = await modal.confirm({
          content: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.DeviceNotFulfilled', {
            count: deviceNotFullfiled
          })
        });
      }
    }

    if (confirmed) {
      submitForm();
    }
  };

  const submitForm = () => {
    const gpioService = serviceMap['GPIO'];
    const vpmService = serviceMap['VPM'];
    const bpmService = serviceMap['BPM'];
    const speedSirenService = serviceMap['SPEEDSIREN'];
    let gpioConfigs = [];
    if (gpioList?.length > 0) {
      gpioConfigs = gpioList.map(item => {
        const value = JSON.stringify(
          toGpioConfig(item, item.input.startsWith('digin.') ? diginFormConfig : digoutFormConfig)
        );
        const dotIndex = item.input.indexOf('.');
        const input = item.input.substring(dotIndex + 1);
        if (item.custom) {
          const custom = {
            overrides: {}
          };

          if (item.custom_name) {
            custom.overrides.label = item.custom_name;
          }
          if (item.custom_state1 && item.custom_state2) {
            custom.overrides.verb = item.custom_state1 + ',' + item.custom_state2;
          }
          const extras = JSON.stringify(custom);
          return {
            service: gpioService,
            key: `${input}.${item.channel}`,
            value,
            extras
          };
        }

        return {
          service: gpioService,
          key: `${input}.${item.channel}`,
          value
        };
      });
      // Process VPM, BPM and SpeedSiren
      gpioList.forEach(item => {
        if (item.vpm) {
          gpioConfigs = [
            ...gpioConfigs,
            ...createServiceConfig(item.channel, item.vpm, vpmService)
          ];
        }
        if (item.bpm) {
          gpioConfigs = [
            ...gpioConfigs,
            ...createServiceConfig(item.channel, item.bpm, bpmService)
          ];
        }
        if (item.speedsiren) {
          gpioConfigs = [
            ...gpioConfigs,
            ...createServiceConfig(item.channel, item.speedsiren, speedSirenService)
          ];
        }
        if (item.led) {
          gpioConfigs = [
            ...gpioConfigs,
            ...createServiceConfig(item.channel, item.led, speedSirenService, '.1')
          ];
        }
        if (item.idle) {
          gpioConfigs = [
            ...gpioConfigs,
            ...createServiceConfig(item.channel, item.idle, isVPMEnabled ? vpmService : bpmService)
          ];
        }
      });
    }

    form.validateFields().then(values => {
      const seatbeltSpeedThreshold = form.getFieldValue('seatbelt.speed.threshold');
      if (seatbeltSpeedThreshold) {
        gpioConfigs.push({
          service: gpioService,
          key: 'seatbelt.speed.threshold',
          value: localization.convertSpeedWithUnit(
            Number(seatbeltSpeedThreshold),
            UNITS.KM,
            localization?.formats?.speed?.unit || UNITS.KM,
            0
          )
        });
      }
      const configurationTemplateRequest = {
        configurationTemplate: {
          name: values.name,
          description: values.description,
          type: 'GPIO',
          company: {
            id: currentCompany.id
          },
          status: 'ENABLED',
          default: values.default,
          configurations: gpioConfigs
        }
      };

      configurationTemplateRequest.deviceIds = [...new Set(selectedRowKeys)];

      if (action === 'add') {
        addGpioConfigurationTemplate({ body: configurationTemplateRequest })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      } else {
        updateGpioConfigurationTemplate({ body: configurationTemplateRequest, id: templateId })
          .then(handleResult)
          .catch(error => {
            openToast({
              type: ToastType.Error,
              message: error
            });
          });
      }
    });
  };

  const handleResult = result => {
    const response = result.data;
    if (result.error) {
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(
            'CompanyConfig.DeviceConfigurations.GPIOTemplates.Error'
          )} ${parseErrorMessage(result?.error?.data)}`
        })
      );
      return;
    }
    if (response?.errors?.length > 0) {
      const mainMessage = t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarning', {
        count: response.associatedDevices?.length
      });
      const errorMessages = response.errors.map(err => (
        <React.Fragment>
          {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.ResultWarningDetail', {
            count: err.devices?.length,
            error: t(
              'CompanyConfig.DeviceConfigurations.GPIOTemplates.error.' + err.message,
              err.message
            )
          })}
          <br />
        </React.Fragment>
      ));
      const finalMessage = (
        <React.Fragment>
          {mainMessage}
          <br />
          {errorMessages}
        </React.Fragment>
      );
      dispatch(
        openToast({
          type: ToastType.Warning,
          message: finalMessage
        })
      );

      dispatch(fetchFleets());
      canHistoryGoBack(history, PATHS.GPIO_DEFAULT);
      return;
    }

    dispatch(fetchFleets());
    dispatch(
      openToast({
        type: ToastType.Success,
        message: t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Success')
      })
    );
    canHistoryGoBack(history, PATHS.GPIO_DEFAULT);
  };

  const toGpioConfig = (cfg, formConfig) => {
    const fieldsToExclude = ['channel', 'input'];
    const isInput = cfg.input.startsWith('digin.');
    const gpio = {};
    formConfig.forEach(cfgField => {
      if (cfgField.type === 'io' && cfg.io) {
        const innerField = cfgField.field.find(item => item.name === cfg.io);
        gpio[innerField.field] = cfg.io;
        if (innerField.verb) {
          gpio['verb'] = innerField.verb;
        }
        if (isInput && cfg[cfg.io]) {
          const ioFields = cfg[cfg.io];
          Object.keys(ioFields).forEach(key => (gpio[key] = ioFields[key]));
        }
      } else if (!fieldsToExclude.includes(cfgField.field) && cfg[cfgField.field]) {
        gpio[cfgField.field] = cfg[cfgField.field];
      }
    });
    return gpio;
  };

  useEffect(() => {
    if (templateId && !isFetchingGpioData) {
      const editingTemplate = { ...templates.find(item => item.id === templateId) };
      if (!diginIODefinition || !digoutIODefinition || !editingTemplate.configurations) {
        return;
      }
      form.setFieldsValue(editingTemplate);
      const seatbeltSpeedThreshold = editingTemplate.configurations.find(
        item => item.key === 'seatbelt.speed.threshold'
      );
      if (seatbeltSpeedThreshold) {
        form.setFieldValue(
          'seatbelt.speed.threshold',
          localization.convertDistance(seatbeltSpeedThreshold.value, 0)
        );
      }
      setCompanyLevel(editingTemplate.default);
      setPreviousCompanyLevel(editingTemplate.default);
      const selectedDeviceIds = editingTemplate.associations?.devices?.map(d => d.id);
      setSelectedRowKeys(selectedDeviceIds);
      setSelectedRowKeysForFilteredDevices(selectedDeviceIds);

      const gpioConfigs = enrichWithExtraServiceConfig(
        editingTemplate.configurations,
        serviceMap,
        diginIODefinition,
        digoutIODefinition,
        channelTypes,
        localization
      );

      setGpioList(gpioConfigs);
    }
  }, [templates, templateId, isFetchingGpioData]);

  useEffect(() => {
    dispatch(
      setPageTitle(
        t(
          action === 'add'
            ? 'CompanyConfig.DeviceConfigurations.GPIOTemplates.AddTemplate'
            : 'CompanyConfig.DeviceConfigurations.GPIOTemplates.UpdateTemplate'
        )
      )
    );
    dispatch(setBackButton(true));
  }, [dispatch, t]);

  useEffect(() => {
    const deviceKeys = new Set(devices.map(d => d.id));
    // Keep only the state of the selected devices that are out of the filtered list.
    let newSelectedKeys = selectedRowKeys.filter(k => !deviceKeys.has(k));
    newSelectedKeys = [...newSelectedKeys, ...selectedRowKeysForFilteredDevices];
    setSelectedRowKeys(newSelectedKeys);
  }, [selectedRowKeysForFilteredDevices]);

  useEffect(() => {
    setDiginData(gpioList.filter(cfg => cfg.input.startsWith('digin.')));
    setDigoutData(gpioList.filter(cfg => cfg.input.startsWith('digout.')));
  }, [gpioList]);

  const initializing = isFetching || isFetchingFleets || isFetchingVehicles || isFetchingGpioData;

  const allChannelEmpty = useMemo(() => {
    for (const { key, form } of channelTypes) {
      const channelType = `${form}.${key}`;
      const channels = getChannels(channelType);
      if (channels.length !== 0) {
        return false;
      }
    }
    return true;
  }, [channelTypes, gpioList]);

  if (initializing) {
    return <Spin size="large"></Spin>;
  }

  const formItemStyle = {
    marginBottom: '3px' // Adjust the bottom margin as needed
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      // Update the selected rows in the state
      setSelectedRowKeysForFilteredDevices(selectedRowKeys);
      setDirty(true);
    }
  };

  const getGPIORowKey = record => `${record.input}-${record.channel}`;

  const inGPIOData = toTableStructure(channelTypes, diginData);
  const outGPIOData = toTableStructure(channelTypes, digoutData);

  return (
    <>
      {contextHolder}
      <EditRouteGuard when={dirty} navigate={history.push} />
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allValues) => {
          setDirty(true);
          setFormValid(allValues.name && allValues.name.trim() !== '');
        }}
      >
        <Card className={style.headerSectionCard}>
          <HeaderFormSection
            formItemStyle={formItemStyle}
            setCompanyLevel={setCompanyLevel}
            templateName={'GPIO'}
            customComponent={
              <Form.Item
                style={formItemStyle}
                className={style.seatbeltSpeedInput}
                tooltip={
                  gpioConfigs?.find(c => c.key === 'seatbelt.speed.threshold')?.description || ''
                }
                label={
                  t('CompanyConfig.DeviceConfigurations.GPIOTemplates.Seatbelt Speed Threshold') +
                  ' (' +
                  localization.formats.speed.unit_per_hour +
                  ')'
                }
                rules={inputValidator().number}
                name="seatbelt.speed.threshold"
                initialValue={localization.convertDistance(seatbeltSpeedThresholdDefault, 0)}
              >
                <InputNumber
                  precision={0}
                  placeholder={localization.convertDistance(seatbeltSpeedThresholdDefault, 0)}
                />
              </Form.Item>
            }
          />
        </Card>
        <Card className={style.configSectionCard}>
          <div className={style.configSectionContainer}>
            <Tooltip
              placement="top"
              title={
                allChannelEmpty
                  ? t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AllChannelIsAssigned')
                  : undefined
              }
              trigger="hover"
            >
              <Button
                disabled={allChannelEmpty}
                onClick={() => {
                  setEditingIndex(-1);
                  setEditingConfig({});
                  setModalVisible(true);
                }}
              >
                <PlusOutlined />
                {t('CompanyConfig.DeviceConfigurations.GPIOTemplates.AddConfig')}
              </Button>
            </Tooltip>
            {modalVisible ? (
              <GPIOFormModal
                visible={modalVisible}
                onCancel={handleCancel}
                onSubmit={handleSubmit}
                diginForm={diginFormConfig}
                digoutForm={digoutFormConfig.concat(extraConfigs)}
                values={editingConfig}
                onValidate={handleValidate}
                getChannels={getChannels}
                channelTypes={channelTypes}
              />
            ) : null}
          </div>
          {inGPIOData?.length > 0 &&
            inGPIOData.map(digin => {
              return (
                digin.data?.length > 0 && (
                  <>
                    <Divider orientation="left" orientationMargin={0} plain>
                      {t(
                        'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + digin.value,
                        digin.value
                      )}
                    </Divider>
                    <Table
                      columns={gpioDiginEditColumns(
                        t,
                        inBiasMap,
                        inTriggerMap,
                        inOccurrenceMap,
                        diginIODefinition,
                        handleEdit,
                        handleDelete
                      )}
                      dataSource={sortBy(digin.data, 'channel')}
                      pagination={false}
                      rowKey={getGPIORowKey}
                    ></Table>
                  </>
                )
              );
            })}
          {outGPIOData?.length > 0 &&
            outGPIOData.map(digout => {
              return (
                digout.data?.length > 0 && (
                  <>
                    <Divider orientation="left" orientationMargin={0} plain>
                      {t(
                        'CompanyConfig.DeviceConfigurations.GPIOTemplates.' + digout.value,
                        digout.value
                      )}
                    </Divider>
                    <Table
                      columns={gpioDigoutEditColumns(
                        t,
                        outBiasMap,
                        outTriggerMap,
                        outOccurrenceMap,
                        vpmConfigs,
                        bpmConfigs,
                        idleConfigs,
                        sirenConfigs,
                        handleEdit,
                        handleDelete
                      )}
                      dataSource={sortBy(digout.data, 'channel')}
                      pagination={false}
                      rowKey={getGPIORowKey}
                    ></Table>
                  </>
                )
              );
            })}
        </Card>
      </Form>
      {companyLevel && (
        <Card className={style.deviceSectionCardCompanyLevel}>
          <Alert
            message={t('CompanyConfig.DeviceConfigurations.CompanyLevelNote', {
              templateName: 'GPIO'
            })}
            type="info"
            showIcon
          />
        </Card>
      )}
      {!companyLevel && gpioModalVisible && (
        <GPIOTableModal
          visible={gpioModalVisible}
          templateName={gpioModalTempateName}
          inBiasMap={inBiasMap}
          inOccurrenceMap={inOccurrenceMap}
          inTriggerMap={inTriggerMap}
          outBiasMap={outBiasMap}
          outOccurrenceMap={outOccurrenceMap}
          outTriggerMap={outTriggerMap}
          diginIODefinition={diginIODefinition}
          vpmData={vpmConfigs}
          bpmData={bpmConfigs}
          speedsirenData={sirenConfigs}
          idleData={idleConfigs}
          data={gpioModalData}
          channelTypes={channelTypes}
          onClose={() => setGpioModalVisible(false)}
        ></GPIOTableModal>
      )}
      {!companyLevel && (
        <DevicesAssignmentTable
          filteredDevices={companyHermesDevices}
          devices={devices}
          setDevices={setDevices}
          templates={templates}
          currentCompany={currentCompany}
          handleViewTemplate={handleViewGpio}
          rowSelection={rowSelection}
          templateType={templateTypeEnum.GPIO}
        />
      )}
      <Card className={style.footerSectionCard}>
        <Space>
          <Button
            type="primary"
            size="large"
            disabled={!dirty || !formValid || isAddingTemplating || isUpdatingTemplating}
            onClick={handleSaveForm}
            id={BUTTON_IDS.gpioFormSave}
          >
            {t('Common.SaveButton')}
          </Button>
          <Button size="large" id={BUTTON_IDS.gpioFormCancel} onClick={history.goBack}>
            {t('Common.CancelButton')}
          </Button>
        </Space>
      </Card>
      <EditRouteGuard when={dirty} navigate={history.push}></EditRouteGuard>
    </>
  );
};
