import React, { useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FeatureFlag,
  services,
  entities,
  GlobalRoles,
  Can,
  useCan,
  useCanFeatureFlag
} from 'features/permissions';
import { confirmationModal } from 'components/ant/Button/confirmationModal/confirmationModal';
import { BUTTON_IDS } from 'utils/globalConstants';
import './EllipsisButton.scss';
import { Configure } from '../../containers/Administration/Devices/DeviceConfigurations/Configure';

export const EllipsisButton = ({
  data,
  path,
  handleDeleteAction,
  typeOfEntityToDelete,
  hideEdit,
  hideConfig,
  hideDelete,
  hideCopy,
  showCalibrate,
  showGPIO,
  showComplete,
  enableRestoreAction,
  handleAction,
  canEnableLoginAsUser,
  handleLogInAsUser,
  customAction,
  canUse
}) => {
  const { t } = useTranslation();
  const speedAssistFlag = useCanFeatureFlag({
    featureFlag: FeatureFlag.deviceConfig_speedAssist.flag
  });
  const can = useCan();
  const canControlSpeedAssistConfiguration = can({
    ...(speedAssistFlag
      ? {
          oneOfRoles: [GlobalRoles.Reseller, GlobalRoles.SiteAdmin, GlobalRoles.Admin]
        }
      : []),
    oneOfCompanyServices: [services.SPEEDASSIST]
  });

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [size, setSize] = useState();

  const showLargeDrawer = () => {
    setPopoverOpen(false);
    setSize('large');
    setDrawerOpen(true);
  };

  const onClose = () => {
    setDrawerOpen(false);
  };

  const handleLinkClick = event => {
    event.preventDefault(); // Prevent default link behavior
    showLargeDrawer(); // Open the drawer
  };

  const handlePopoverToggle = nextShow => {
    setPopoverOpen(nextShow);
  };

  const ActionsContainer = data => (
    <Popover
      id="popover-device-menu"
      show={popoverOpen}
      onClick={() => handlePopoverToggle(false)}
      style={{ minWidth: '12rem', transform: 'translate(1235px, 257px)' }}
    >
      <div>
        <ul className={'tn-menu-context'}>
          <Can everyEntity={[entities[`${canUse}_VIEW`]]}>
            <Link
              to={{
                pathname: path + `id/${data.id ? data.id : data.Id}`,
                state: { dataToEdit: data }
              }}
            >
              <li>{t('Common.EllipsisButton.View')}</li>
            </Link>
          </Can>
          {!hideEdit && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname:
                    data.isDVIR && canUse === entities.PRETRIPCHECKLIST
                      ? `${path}editDvir/id/${data.id ? data.id : data.Id}`
                      : `${path}edit/id/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Edit')}</li>
              </Link>
            </Can>
          )}
          {!hideConfig &&
            speedAssistFlag &&
            canControlSpeedAssistConfiguration &&
            data?.services?.includes('SPEEDASSIST') && (
              <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
                <div onClick={handleLinkClick}>
                  <li>{t('Devices.DeviceConfigurations.Configure')}</li>
                </div>
              </Can>
            )}
          {!hideCopy && (
            <Can everyEntity={[entities[`${canUse}_COPY`]]}>
              <Link
                to={{
                  pathname: `${path}copy/id/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Copy')}</li>
              </Link>
            </Can>
          )}
          {showCalibrate && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname: `${path}calibrate/id/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Calibrate')}</li>
              </Link>
            </Can>
          )}
          {showGPIO && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname: `${path}gpio/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.GPIO')}</li>
              </Link>
            </Can>
          )}
          {showComplete && (
            <Can everyEntity={[entities[`${canUse}_UPDATE`]]}>
              <Link
                to={{
                  pathname: `${path}complete/${data.id ? data.id : data.Id}`,
                  state: { dataToEdit: data }
                }}
              >
                <li>{t('Common.EllipsisButton.Complete')}</li>
              </Link>
            </Can>
          )}
          {customAction && <>{customAction}</>}
          {canEnableLoginAsUser && (
            <li onClick={() => handleLogInAsUser(data)}>{t('Users.LogInAsUser.LogInAsUser')}</li>
          )}
          {!hideDelete && (
            <Can everyEntity={[entities[`${canUse}_DESTROY`]]}>
              <li
                style={{
                  color: 'red'
                }}
                onClick={() => {
                  confirmationModal(
                    `${t('Common.DeleteButton')} ${data.name || data.Name || data.username}`,
                    `${t('Common.SureDelete')} ${typeOfEntityToDelete} ${data.name ||
                      data.Name ||
                      data.username}?`,
                    t('Common.DeleteButton'),
                    t('Common.CancelButton'),
                    handleDeleteAction(data),
                    'delete'
                  );
                  document.body.click();
                }}
              >
                {t('Common.EllipsisButton.Delete')}
              </li>
            </Can>
          )}
          {enableRestoreAction && (
            <Can everyEntity={[entities[`${canUse}_RESTORE`]]}>
              <li
                onClick={() => {
                  handleAction({ data, action: 'restore' });
                  document.body.click();
                }}
              >
                {t('Common.EllipsisButton.Restore')}
              </li>
            </Can>
          )}
        </ul>
      </div>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="left"
        rootClose={true}
        overlay={ActionsContainer(data)}
        show={popoverOpen} // Control visibility based on state
        onToggle={handlePopoverToggle}
      >
        <button
          id={BUTTON_IDS.tableEllipsis}
          style={{ border: 'none' }}
          className={'tn-btn-elipsis'}
        >
          <i style={{ fontSize: '26px' }} className={'tn-i-elipsis'} />
        </button>
      </OverlayTrigger>
      <Configure open={drawerOpen} onClose={onClose} size={size} width={800} data={data} />
    </>
  );
};
