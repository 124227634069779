import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import moment from 'moment';

//components
import { Input, Select, DatePicker } from 'components/ant';
import { Col, Row, Button, InputNumber, Form } from 'antd';
import EditRouteGuard from 'components/edit-route-guard/EditRouteGuard';
import { FormLabel } from 'react-bootstrap';
import { DocumentsDisplay } from 'components/Documents';
import { FileUpload } from 'components/FileUpload';
import { LoadingCentered } from 'components/loading/Loading';

//slices
import { useCurrentCompany } from 'features/company/companySlice';
import { useIsFetching as useIsLoadingDrivers } from 'features/users/usersSlice';
import { useUser } from 'features/user/userSlice';
import { openToast } from 'features/toasts/toastsSlice';
import {
  addSchedule,
  updateSchedule,
  useScheduleById,
  fetchScheduleById,
  useIsScheduleByIdFetching
} from 'features/driverManagement';
import { useLocalization } from 'features/localization/localizationSlice';
import { attachFiles } from 'features/vehicleMaintenance/schedulesSlice';

//helpers
import { setBackButton, setPageTitle } from 'features/page/pageSlice';
import { getIDFromPathname, filterDriversForBranch, prepareDataForSelect } from 'utils/methods';
import { parseErrorMessage } from 'utils/strings';
import { prepareScheduleData, renderUploadedFiles, handleFileAttachment } from './helpers';

//constants
import { ToastType } from 'components/notifications/toasts/Toast';
import { DATE_FORMAT } from 'features/localization/localization';
import { Paths, ACTIONS, LabelAlignLeft, SizeLarge, initialValues } from './constants';

//styles
import styles from './DriverManagement.module.scss';
import { BUTTON_IDS } from 'utils/globalConstants';
import { getConfig, useConfig } from 'features/easydocs/documentsSlice';

export const DriverManagementForm = ({
  action,
  schedules = [],
  types = [],
  branches = [],
  drivers = []
}) => {
  const { t } = useTranslation();
  const id = getIDFromPathname(window.location.pathname);
  const dispatch = useDispatch();
  const history = useHistory();
  const company = useCurrentCompany();
  const isEdit = action === ACTIONS.EDIT;
  const isLoadingDrivers = useIsLoadingDrivers();
  const isFetching = useIsScheduleByIdFetching();
  const [isSaving, setIsSaving] = useState(false);
  const [promptModalWhenLeaving, setPromptModalWhenLeaving] = useState(false);
  const branchesForSelect = prepareDataForSelect([
    { id: 0, name: `${t('Common.AllBranches')}` },
    ...branches
  ]);
  const typesForSelect = prepareDataForSelect(types);
  const [driversForSelect, setDriversForSelect] = useState([]);
  const schedule = useScheduleById();
  const scheduleData = isEdit && prepareScheduleData(schedule, drivers, branches);
  const [form] = Form.useForm();
  const localization = useLocalization();
  const dateFormat = localization.formats.time.formats.dmY;
  const user = useUser();
  const [files, saveFiles] = useState([]);
  const uploadConfig = useConfig();

  useEffect(() => {
    dispatch(fetchScheduleById({ id, query: { embed: 'events' } }));
  }, [id, dispatch]);

  useEffect(() => {
    dispatch(getConfig(company?.id));
  }, [company]);

  //methods on load
  useEffect(() => {
    dispatch(setBackButton(true));
    dispatch(
      setPageTitle(
        isEdit
          ? `${t('Common.Edit')} ${scheduleData?.name || ''}`
          : t('DriverManagement.Form.TitleAdd')
      )
    );
  }, [isEdit, scheduleData.name]);

  useEffect(() => {
    setDriversForSelect(prepareDataForSelect(drivers));
  }, [drivers]);

  useEffect(() => {
    if (!schedule || Object.keys(schedule).length === 0) {
      return;
    }
    form.resetFields();
    // completionParams
    const completionParams = schedule?.completionParameters
      ? JSON.parse(schedule.completionParameters)
      : {};
    if (completionParams.notes) {
      form.setFieldsValue({ notes: completionParams.notes });
    }
    if (completionParams?.documents) {
      const documentsForScheduleView = completionParams.documents.map(document => ({
        ...document,
        name: document.filename,
        size: document.filesize,
        isSaved: true
      }));
      saveFiles([...documentsForScheduleView]);
    }
  }, [schedule]);

  const handleAttachment = event => {
    const {
      target: { files: uploadFiles }
    } = event;
    handleFileAttachment({
      uploadFiles,
      files,
      dispatch,
      updateFilesCb,
      maxFileSizeBytes: uploadConfig?.maxFileSizeBytes
    });
  };

  const updateFilesCb = payload => {
    saveFiles(payload);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const values = form.getFieldsValue();
    const filesToAttach = files.filter(file => !file.isSaved);
    const parameters = JSON.stringify({ days: values.days });
    const payload = {
      ...values,
      companyId: company?.id,
      parameters,
      ...(isEdit && { id: scheduleData?.id }),
      schedulesOn: values.schedulesOn
        ? moment(values.schedulesOn).format(DATE_FORMAT.DEFAULT)
        : moment().format(DATE_FORMAT.DEFAULT),
      completionParameters: JSON.stringify({ notes: values.notes })
    };

    try {
      const actionCreator = isEdit
        ? updateSchedule({ id: scheduleData?.id, body: payload })
        : addSchedule({ body: payload });
      const response = await dispatch(actionCreator);

      if (response.error) {
        dispatch(
          openToast({
            type: ToastType.Error,
            message: `${t(`DriverManagement.error${isEdit ? 'Edit' : 'Add'}Toast`, {
              entity: t('DriverManagement.Type'),
              name: scheduleData?.name || values.name
            })} ${parseErrorMessage(response?.payload)}`
          })
        );
        setIsSaving(false);
      } else {
        // success
        await dispatch(attachFiles(filesToAttach, scheduleData.id || response?.payload?.id));
        dispatch(
          openToast({
            type: ToastType.Success,
            message: `${t(`DriverManagement.success${isEdit ? 'Edit' : 'Add'}Toast`, {
              entity: t('DriverManagement.Type'),
              name: scheduleData?.name || values.name
            })}`
          })
        );
        // Go back to the table page
        setPromptModalWhenLeaving(false);
        history.push(Paths.MAIN_PAGE);
      }
    } catch (err) {
      console.error(err);
      dispatch(
        openToast({
          type: ToastType.Error,
          message: `${t(`DriverManagement.error${isEdit ? 'Edit' : 'Add'}Toast`, {
            entity: t('DriverManagement.Type'),
            name: scheduleData?.name || values.name
          })} ${parseErrorMessage(err)}`
        })
      );
      setIsSaving(false);
    }
  };

  const setDirtyForm = () => {
    setPromptModalWhenLeaving(true);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleBranchChange = branchId => {
    //reset drivers Select when branch is changed
    form.setFieldsValue({ driver: null });

    if (branchId === 0) {
      // If 'All branches" selected, show all drivers
      setDriversForSelect(prepareDataForSelect(drivers));
      return;
    }

    // if a branch or "No branch" option is selected
    const driversForBranch = filterDriversForBranch(drivers, branchId);
    setDriversForSelect(prepareDataForSelect(driversForBranch));
  };

  if (isFetching) {
    return <LoadingCentered />;
  }

  return (
    <div className={styles.formContainer}>
      <EditRouteGuard when={promptModalWhenLeaving} navigate={history.push} />
      <Form
        layout="vertical"
        initialValues={scheduleData || initialValues}
        form={form}
        onFinish={handleSave}
        onValuesChange={setDirtyForm}
      >
        <div className={styles.formCard}>
          <Row>
            <Col span={12}>
              <Form.Item
                className={styles.inputLeft}
                name="name"
                labelAlign={LabelAlignLeft}
                label={t(`DriverManagement.Form.Name`)}
                colon={false}
                rules={[
                  {
                    required: true,
                    message: t('DriverManagement.Form.NameRequired')
                  }
                ]}
              >
                <Input size={SizeLarge} placeholder={t('DriverManagement.Form.NamePlaceholder')} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className={styles.inputRight}
                name="branchId"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`Common.Branch`)}
              >
                <Select
                  size={SizeLarge}
                  placeholder={t(`DriverManagement.Form.SelectBranch`)}
                  data={branchesForSelect}
                  onChange={handleBranchChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                className={styles.inputLeft}
                name="manageTypeId"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`Common.Type`)}
                rules={[
                  {
                    required: true,
                    message: t('DriverManagement.Form.TypeRequired')
                  }
                ]}
              >
                <Select
                  size={SizeLarge}
                  placeholder={t(`DriverManagement.Form.SelectType`)}
                  data={typesForSelect}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className={styles.inputRight}
                name="entityId"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`Common.Driver`)}
                rules={[
                  {
                    required: true,
                    message: t('DriverManagement.Form.DriverRequired')
                  }
                ]}
              >
                <Select
                  size={SizeLarge}
                  placeholder={t(`DriverManagement.Form.SelectDriver`)}
                  data={driversForSelect}
                  loading={isLoadingDrivers}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                className={styles.inputLeft}
                name="schedulesOn"
                labelAlign={LabelAlignLeft}
                colon={false}
                label={t(`DriverManagement.Form.Date`)}
                rules={[
                  {
                    required: true,
                    message: t('DriverManagement.Form.DateRequired')
                  }
                ]}
              >
                <DatePicker
                  size={SizeLarge}
                  placeholder={t('Common.SelectDate')}
                  controlClass={styles.datePicker}
                  format={dateFormat}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className={styles.inputRight}
                name="days"
                labelAlign={LabelAlignLeft}
                label={t(`DriverManagement.Form.Days`)}
                colon={false}
              >
                <InputNumber className={styles.daysInput} size={SizeLarge} min={0} max={30} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={16}>
              <Form.Item
                className={styles.inputLeft}
                name="notes"
                labelAlign={LabelAlignLeft}
                label={t('DriverManagement.Form.Notes')}
              >
                <Input.TextArea size={SizeLarge} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <FormLabel className={styles.customFormLabel}>
              {t('VehicleMntSchedules.View.Documents')}
            </FormLabel>
          </Row>
          <Row>
            <Col span={16}>
              <div className={styles.documentsDisplay}>
                <DocumentsDisplay
                  rows={renderUploadedFiles({
                    files,
                    currentUser: user,
                    dateFormat,
                    updateFilesCb,
                    dispatch
                  })}
                />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.fileUpload}>
                <FileUpload onAdd={handleAttachment} />
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.formFooter}>
          <Button
            id={BUTTON_IDS.driverMgtFormSave}
            size={SizeLarge}
            htmlType="submit"
            type="primary"
            loading={isSaving}
            className={styles.saveButton}
          >
            {t(`Common.Save`)}
          </Button>
          <Button
            id={BUTTON_IDS.driverMgtFormCancel}
            size={SizeLarge}
            type="secondary"
            className={styles.cancelButton}
            onClick={handleCancel}
          >
            {t(`Common.Cancel`)}
          </Button>
        </div>
      </Form>
    </div>
  );
};
