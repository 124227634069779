import i18n from 'i18next';
import { format } from 'utils/dates';
import { Button, Space } from 'antd';
import { capitalize, orderBy } from 'lodash';

export const DeviceLens = {
  All: i18n.t('Devices.Lens.All'),
  Assigned: i18n.t('Devices.Lens.Assigned'),
  Standalone: i18n.t('Devices.Lens.Standalone'),
  Deleted: i18n.t('Devices.Lens.Deleted')
};

export const initialValues = {
  id: 0,
  name: '',
  path: null,
  version: null,
  status: null,
  companyId: 0,
  vehicle: {
    id: 0
  },
  type: {},
  did: 0,
  registration: 0,
  externalId: 0,
  serialNumber: '',
  imei: '',
  sim: '',
  phone: '',
  operatingSystem: '',
  firmwareVersion: '',
  modemType: '',
  modemImei: '',
  softwareVersion: '',
  note: '',
  auto: false,
  iapId: null,
  releaseVersion: null,
  tags: null
};

export const deviceMetersColumns = t => {
  return [
    {
      title: t('Devices.Meters.MeterType'),
      dataIndex: 'meterType'
    },
    {
      title: t('Devices.Meters.Source'),
      dataIndex: 'source'
    },
    {
      title: t('Devices.Meters.Device'),
      dataIndex: 'device'
    },
    {
      title: t('Devices.Meters.Value'),
      dataIndex: 'value'
    }
  ];
};

export const deviceMetersSAColumns = t => {
  return [
    {
      title: t('Devices.Meters.MeterType'),
      dataIndex: 'meterType'
    },
    {
      title: t('Devices.Meters.Source'),
      dataIndex: 'source'
    },
    {
      title: t('Devices.Meters.Device'),
      dataIndex: 'device'
    },
    {
      title: t('Devices.Meters.Base'),
      dataIndex: 'base'
    },
    {
      title: t('Devices.Meters.Diff'),
      dataIndex: 'diff'
    },
    {
      title: t('Devices.Meters.Value'),
      dataIndex: 'value'
    },
    {
      title: t('Devices.Meters.RebasedAt'),
      dataIndex: 'rebasedAt'
    },
    {
      title: t('Devices.Meters.RebasedBy'),
      dataIndex: 'rebasedBy'
    }
  ];
};

export const OS_LABELS = {
  HERMES: 'HERMES',
  IVU: 'IVU'
};

export const XLSX_FILE_TITLE = 'Devices';

const NO_OF_COLUMNS = 28;
export const columnWidth = Array.apply(null, { length: NO_OF_COLUMNS }).map(() => ({ wch: 25 }));

export const PATHS = {
  VIEW_DEVICE: '/settings/devices/id',
  NEW_DEVICE: '/settings/devices/newDevice',
  EDIT_DEVICE: '/settings/devices/edit/id',
  AUDIT_DEVICE: '/settings/devices/audit/id',
  AUDIT_BULKEDIT: '/settings/devices/bulk_edit_audit',
  EDIT_DEVICE_AGREEMENT: '/settings/devices/agreement',
  DEVICE_DEFAULT: '/settings/devices'
};

export const deviceAgreementColumns = (t, localization) => {
  return [
    {
      title: t('Agreement.WorkOrder'),
      dataIndex: 'workOrder'
    },
    {
      title: t('Agreement.ContractEndDate'),
      dataIndex: 'contractEndAt',
      render: (id, record) => (
        <span>{format(new Date(record.contractEndAt), localization.formats.time.formats.dby)}</span>
      )
    },
    {
      title: t('Agreement.Description'),
      dataIndex: 'description'
    },
    {
      title: t('Agreement.SubscriptionPack'),
      dataIndex: ['subscriptionPack', 'name']
    }
  ];
};

export const deviceServiceColumns = (t, onPush, onView) => {
  return [
    {
      title: t('Devices.Configuration.Code'),
      dataIndex: 'code'
    },
    {
      title: t('Devices.Configuration.Name'),
      dataIndex: 'name'
    },
    {
      title: t('Devices.Configuration.Description'),
      dataIndex: 'description'
    },
    {
      title: t('Devices.Configuration.Action'),
      render: (id, record) => (
        <Space>
          {onView && (
            <Button
              size="large"
              type="primary"
              onClick={() => {
                onView(record);
              }}
            >
              {t('Devices.Configuration.View')}
            </Button>
          )}
          {onPush && (
            <Button
              size="large"
              type="primary"
              onClick={() => {
                onPush(record);
              }}
            >
              {t('Devices.Configuration.Push')}
            </Button>
          )}
        </Space>
      )
    }
  ];
};

const IQCameraSensitivities = [
  { value: 'light', label: 'CompanyConfig.IQCamera.light' },
  { value: 'medium', label: 'CompanyConfig.IQCamera.medium' },
  { value: 'heavy', label: 'CompanyConfig.IQCamera.heavy' }
];

const IQCameraVolumes = [...Array(16).keys()].map(volume => ({
  label: String(volume),
  value: String(volume)
}));

const IQCameraAudioAlertTypes = {
  NEW_DRIVER_ID: {
    label: 'NEW_DRIVER_ID',
    value: 'NEW_DRIVER_ID'
  },
  TRIP_STARTED: {
    label: 'TRIP_STARTED',
    value: 'TRIP_STARTED'
  },
  TRIP_ENDED: {
    label: 'TRIP_ENDED',
    value: 'TRIP_ENDED'
  },
  CALIBRATION_COMPLETE: {
    label: 'CALIBRATION_COMPLETE',
    value: 'CALIBRATION_COMPLETE'
  },
  LANE_DRIFT: {
    label: 'LANE_DRIFT',
    value: 'LANE_DRIFT'
  },
  TAILGATING_WARNING: {
    label: 'TAILGATING_WARNING',
    value: 'TAILGATING_WARNING'
  },
  TAILGATING_VIOLATED: {
    label: 'TAILGATING_VIOLATED',
    value: 'TAILGATING_VIOLATED'
  },
  STOP_SIGN_DETECTED: {
    label: 'STOP_SIGN_DETECTED',
    value: 'STOP_SIGN_DETECTED'
  },
  STOP_SIGN_VIOLATED: {
    label: 'STOP_SIGN_VIOLATED',
    value: 'STOP_SIGN_VIOLATED'
  },
  SPEED_SIGN_DETECTED: {
    label: 'SPEED_SIGN_DETECTED',
    value: 'SPEED_SIGN_DETECTED'
  },
  SPEED_SIGN_DETECTED_SCHOOL_ZONE: {
    label: 'SPEED_SIGN_DETECTED_SCHOOL_ZONE',
    value: 'SPEED_SIGN_DETECTED_SCHOOL_ZONE'
  },
  SPEEDING_VIOLATION: {
    label: 'SPEEDING_VIOLATION',
    value: 'SPEEDING_VIOLATION'
  },
  HARSH_CORNERING: {
    label: 'HARSH_CORNERING',
    value: 'HARSH_CORNERING'
  },
  HARSH_BRAKING: {
    label: 'HARSH_BRAKING',
    value: 'HARSH_BRAKING'
  },
  HARSH_ACCELERATION: {
    label: 'HARSH_ACCELERATION',
    value: 'HARSH_ACCELERATION'
  },
  ANOMALY: {
    label: 'ANOMALY',
    value: 'ANOMALY'
  },
  SPEED_UPPER_LIMIT_REACHED: {
    label: 'SPEED_UPPER_LIMIT_REACHED',
    value: 'SPEED_UPPER_LIMIT_REACHED'
  },
  DRIVER_DISTRACTION_WARNING: {
    label: 'DRIVER_DISTRACTION_WARNING',
    value: 'DRIVER_DISTRACTION_WARNING'
  },
  DRIVER_DISTRACTION_VIOLATION: {
    label: 'DRIVER_DISTRACTION_VIOLATION',
    value: 'DRIVER_DISTRACTION_VIOLATION'
  },
  SHUTTING_DOWN: {
    label: 'SHUTTING_DOWN',
    value: 'SHUTTING_DOWN'
  },
  WIFI_CONNECTED: {
    label: 'WIFI_CONNECTED',
    value: 'WIFI_CONNECTED'
  },
  WIFI_DISCONNECTED: {
    label: 'WIFI_DISCONNECTED',
    value: 'WIFI_DISCONNECTED'
  },
  EXTERNAL_VIDEO_CAPTURE_STARTED: {
    label: 'EXTERNAL_VIDEO_CAPTURE_STARTED',
    value: 'EXTERNAL_VIDEO_CAPTURE_STARTED'
  },
  EXTERNAL_IMAGE_CAPTURED: {
    label: 'EXTERNAL_IMAGE_CAPTURED',
    value: 'EXTERNAL_IMAGE_CAPTURED'
  },
  STOPPING_DVR: {
    label: 'STOPPING_DVR',
    value: 'STOPPING_DVR'
  },
  SPEEDING_WARNING: {
    label: 'SPEEDING_WARNING',
    value: 'SPEEDING_WARNING'
  },
  DROWSY_DRIVING_VIOLATION: {
    label: 'DROWSY_DRIVING_VIOLATION',
    value: 'DROWSY_DRIVING_VIOLATION'
  },
  DRIVER_CELLPHONE_DISTRACTION: {
    label: 'DRIVER_CELLPHONE_DISTRACTION',
    value: 'DRIVER_CELLPHONE_DISTRACTION'
  },
  FORWARD_COLLISION_WARNING: {
    label: 'FORWARD_COLLISION_WARNING',
    value: 'FORWARD_COLLISION_WARNING'
  },
  FCW_BEEP_ALERT: {
    label: 'FCW_BEEP_ALERT',
    value: 'FCW_BEEP_ALERT'
  },
  AUDIO_RECORDING_ENABLED: {
    label: 'AUDIO_RECORDING_ENABLED',
    value: 'AUDIO_RECORDING_ENABLED'
  },
  ROLL_OVER_DETECTED: {
    label: 'ROLL_OVER_DETECTED',
    value: 'ROLL_OVER_DETECTED'
  },
  UNBUCKLED_SEAT_BELT: {
    label: 'UNBUCKLED_SEAT_BELT',
    value: 'UNBUCKLED_SEAT_BELT'
  },
  DRIVER_SMOKING_DISTRACTION: {
    label: 'DRIVER_SMOKING_DISTRACTION',
    value: 'DRIVER_SMOKING_DISTRACTION'
  },
  DRIVER_DRINKING_DISTRACTION: {
    label: 'DRIVER_DRINKING_DISTRACTION',
    value: 'DRIVER_DRINKING_DISTRACTION'
  },
  DRIVER_TEXTING_DISTRACTION: {
    label: 'DRIVER_TEXTING_DISTRACTION',
    value: 'DRIVER_TEXTING_DISTRACTION'
  },
  TRIP_UPLOAD_CRITERIA_MET: {
    label: 'TRIP_UPLOAD_CRITERIA_MET',
    value: 'TRIP_UPLOAD_CRITERIA_MET'
  },
  TRIP_WITH_UPLOAD_CRITERIA_ENDED: {
    label: 'TRIP_WITH_UPLOAD_CRITERIA_ENDED',
    value: 'TRIP_WITH_UPLOAD_CRITERIA_ENDED'
  },
  DRIVER_LIZARD_EYE_DISTRACTION: {
    label: 'DRIVER_LIZARD_EYE_DISTRACTION',
    value: 'DRIVER_LIZARD_EYE_DISTRACTION'
  }
};

export const IQCameraDeviceConfig = {
  Sensitivities: IQCameraSensitivities,
  DefaultSensitivityValue: 'light',
  Volumes: IQCameraVolumes,
  DefaultVolumeValue: '15',
  AudioAlertTypes: IQCameraAudioAlertTypes,
  DefaultAudioAlertTypes: [
    'ANOMALY',
    'DRIVER_DISTRACTION_VIOLATION',
    'DRIVER_DISTRACTION_WARNING',
    'DRIVER_LIZARD_EYE_DISTRACTION',
    'DROWSY_DRIVING_VIOLATION',
    'EXTERNAL_IMAGE_CAPTURED',
    'EXTERNAL_VIDEO_CAPTURE_STARTED',
    'FORWARD_COLLISION_WARNING',
    'HARSH_ACCELERATION',
    'HARSH_BRAKING',
    'HARSH_CORNERING',
    'LANE_DRIFT',
    'NEW_DRIVER_ID',
    'SHUTTING_DOWN',
    'SPEEDING_VIOLATION',
    'SPEEDING_WARNING',
    'SPEED_SIGN_DETECTED',
    'SPEED_SIGN_DETECTED_SCHOOL_ZONE',
    'SPEED_UPPER_LIMIT_REACHED',
    'STOPPING_DVR',
    'STOP_SIGN_DETECTED',
    'STOP_SIGN_VIOLATED',
    'TAILGATING_VIOLATED',
    'TAILGATING_WARNING',
    'TRIP_ENDED',
    'TRIP_STARTED',
    'WIFI_CONNECTED',
    'WIFI_DISCONNECTED'
  ],
  DefaultEnableDriverCamera: true
};

const devStatsKeyTranslate = devKey => {
  switch (devKey) {
    case 'DeviceName':
      return i18n.t('Tracking.Columns.Device Name');
    case 'DeviceModel':
    case 'ModelName':
      return i18n.t('Common.Model');
    case 'FirmwareVersion':
      return i18n.t('Devices.View.FirmwareVersionLabel');
    case 'FirmwareVersionBase':
      return i18n.t('Devices.View.FirmwareVersionBaseLabel');
    case 'FirmwareRegionalVersion':
      return i18n.t('Devices.View.FirmwareRegionalVersionLabel');
    case 'Cellular':
      return i18n.t('Tracking.Cellular');
    case 'PRETRIP':
      return i18n.t('Inspections.Counter');
    case 'SENTINEL':
      return i18n.t('Users.Sentinel');
    case 'SIM':
    case 'sim':
      return i18n.t('Devices.ActualForm.SimLabel');
    case 'SPEEDASSIST':
    case 'SMARTNAV3D':
    case 'APPMANAGER':
    case 'SHELL':
    case 'TRANSTECHCOMMON':
      return i18n.t(`Common.${devKey}`);
    case 'FORM':
      return i18n.t('Forms.Form');
    case 'FBT':
      return i18n.t('FBTManager.Title');
    case 'SMARTJOBS':
      return i18n.t('Common.SmartJobs');
    case 'SENTINEL4':
      return i18n.t('Roles.EntityDescriptionValueWithKeyFromTagField.SENTINEL4');
    case 'PRETRIPCHECK':
      return i18n.t('Common.PretripChecklist');
    case 'MASSMANAGEMENT':
      return i18n.t(`Common.MassManagement`);

    default:
      const version = devKey?.match(/Firmware((\d+)?)Semver/i)?.[1];
      if (version) {
        return i18n.t('Devices.View.FirmwareSemver', { version });
      }
      return i18n.t([
        `Devices.View.${devKey}`,
        `Devices.${devKey}`,
        `Common.${capitalize(devKey)}`,
        devKey
      ]);
  }
};

export const deviceTypeModelTranslate = value => {
  try {
    value = String(value || '');
    switch (value) {
      case 'CAMERA':
      case 'Camera':
        return i18n.t('Entity.Camera');
      case 'IQCamera':
      case 'IQ Camera':
        return i18n.t('Entity.IQ Camera');
      case 'DEFAULT':
        return i18n.t('Default');
      default:
        return i18n.t([`Entity.${value}`, value]);
    }
  } catch (error) {
    return value;
  }
};

export const devStatsTransformer = devStatsObj =>
  orderBy(
    Object.entries(devStatsObj || {}).reduce(
      (a, [devKey, devValue]) =>
        devValue
          ? a.concat([
              {
                key: devKey,
                label: devStatsKeyTranslate(devKey),
                value: deviceTypeModelTranslate(devValue)
              }
            ])
          : a,
      []
    ),
    'label'
  );
