import { API_PATH, API_PATH_WITHOUT_V1 } from 'config';
import { t_error } from 'i18nextConfig';
import request from 'superagent';

export const fetchCameraEvents = (
  startTime,
  endTime,
  devices,
  drivers,
  eventTypes,
  orderField = 'time',
  orderDirection = 'desc',
  rowOffset = 0,
  search,
  onSuccess,
  onError,
  allAttachment,
  videoRequest = false,
  status,
  latestOnly = false,
  canNewVideoRequestApi = false
) => (dispatch, getState) => {
  const requestBody =
    videoRequest && canNewVideoRequestApi
      ? {
          devices,
          drivers,
          eventTypes,
          orderField,
          orderDirection,
          rowOffset,
          search,
          status
        }
      : {
          devices,
          drivers,
          eventTypes,
          orderField,
          orderDirection,
          rowOffset,
          search,
          videoRequest,
          status
        };
  if (allAttachment) {
    requestBody.allAttachment = true;
  }

  if (latestOnly) {
    requestBody.latestOnly = true;
  }

  const userKey = getState().user.current.auth.key;
  const req = request
    .post(
      `${API_PATH}/devices/events/camera${
        videoRequest && canNewVideoRequestApi ? '/video_requests' : ''
      }`
    )
    .query('from=' + encodeURIComponent(startTime))
    .query('to=' + encodeURIComponent(endTime))
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .send(requestBody)
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      console.error(errMsg);
      if (onError) {
        onError(errMsg);
      }
    });
  return req;
};

export const fetchCompanyDVRUsage = ({ companyId, onSuccess, onError }) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request('GET', `${API_PATH_WITHOUT_V1}cameras/lightmetrics/dvr/upload/limit`)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
    });

  return req;
};

export const fetchCompanyCameraHealthSummary = ({ startTime, companyId, onSuccess, onError }) => (
  dispatch,
  getState
) => {
  const userKey = getState().user.current.auth.key;
  const req = request('GET', `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/summary`)
    .query('startTime=' + encodeURIComponent(startTime))
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyCameraHealthEvents = ({
  startTime,
  companyId,
  search = '',
  limit = 100,
  offset = 0,
  healthEventTypes = [],
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request('GET', `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/events`)
    .query('startTime=' + encodeURIComponent(startTime))
    .query({ deviceName: search, offset, limit, healthEventTypes })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCameraHealthEvents = ({
  startTime,
  endTime,
  deviceIds,
  healthEventTypes,
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request('GET', `${API_PATH_WITHOUT_V1}cameras/health/devices/summary`)
    .query({ deviceIds })
    .query({ healthEventTypes })
    .query('from=' + encodeURIComponent(startTime))
    .query('to=' + encodeURIComponent(endTime))
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyDevicePing = ({
  companyId,
  limit = 100,
  offset = 0,
  search = '',
  sortBy = 'lastCommsAt',
  sort = 'asc',
  deviceModels = ['IQCamera', 'MultiIQ'],
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request(
    'GET',
    `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/device-ping`
  )
    .query({ search, offset, limit, sortBy, sort })
    .query('deviceModels=' + deviceModels)
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyDataConsumptionSummary = ({
  companyId,
  startTime,
  endTime,
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request(
    'GET',
    `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/data-consumption/summary`
  )
    .query({ startTime, endTime })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};

export const fetchCompanyDataConsumption = ({
  companyId,
  startTime,
  endTime,
  dataConsumptionRate = '',
  search = '',
  offset = 0,
  limit = 5,
  sort = 'desc',
  sortBy = 'totalMobileConsumption',
  onSuccess,
  onError
}) => (dispatch, getState) => {
  const userKey = getState().user.current.auth.key;
  const req = request(
    'GET',
    `${API_PATH_WITHOUT_V1}cameras/health/company/${companyId}/data-consumption`
  )
    .query({
      from: startTime,
      to: endTime,
      sort,
      sortBy,
      limit,
      offset,
      search,
      dataConsumptionRate
    })
    .set('Authorization', `Token token="${userKey}"`)
    .set('Content-Type', 'application/json')
    .ok(resp => {
      if (resp.ok && onSuccess) {
        onSuccess(resp.body);
        return true;
      }
      return false;
    })
    .on('error', err => {
      const errMsg = t_error(err.response?.body) || err.message;
      if (onError) {
        onError(errMsg);
      }
      return false;
    });
  return req;
};
